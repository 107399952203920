@font-face { font-family: "tt_commonsdemibold"; src: url("../fonts/ttcommons-demibold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-demibold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonslight"; src: url("../fonts/ttcommons-light-webfont.woff2") format("woff2"), url("../fonts/ttcommons-light-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsmedium"; src: url("../fonts/ttcommons-medium-webfont.woff2") format("woff2"), url("../fonts/ttcommons-medium-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsblack"; src: url("../fonts/ttcommons-black-webfont.woff2") format("woff2"), url("../fonts/ttcommons-black-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commons_boldbold"; src: url("../fonts/ttcommons-bold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-bold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsextrabold"; src: url("../fonts/ttcommons-extrabold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-extrabold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsextralight"; src: url("../fonts/ttcommons-extralight-webfont.woff2") format("woff2"), url("../fonts/ttcommons-extralight-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsregular"; src: url("../fonts/ttcommons-regular-webfont.woff2") format("woff2"), url("../fonts/ttcommons-regular-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsthin"; src: url("../fonts/ttcommons-thin-webfont.woff2") format("woff2"), url("../fonts/ttcommons-thin-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }

body {
	min-height: 100vh;
	height: 100%;
	font-family: "tt_commonsregular";
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	.btn {
		border-radius: 50px;
		padding: 10px 30px 9px 30px;
		font-size: 1rem;
		font-family: "tt_commonsdemibold";
		border: none;
		outline: none;
		box-shadow: none !important;
		transition: all 0.5s;

		&.btn-default {
			background: @color2;
			color: #fff;

			&:hover {
				background: darken(@color2, 10%);
			}
		}

		&.btn-blue {
			background: @color1;
			color: #fff;

			&:hover {
				background: darken(@color1, 10%);
			}
		}

		&.btn-gray {
			background: #e9e9e9;
			color: @color1;

			&:hover {
				background: @color1;
				color: #fff;
			}
		}

		&.btn-small {
			font-size: 0.8rem;
			padding: 8px 22px 6px 22px;
		}
	}

	.btn-text {
		font-size: 1rem;
		font-family: "tt_commonsdemibold";
		color: @color2;

		&.btn-blue {
			color: @color1;
			background: none !important;
		}
	}

	ul, ol, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}
}

@color1: #16305d;
@color2: #c72222;

@container-padding: 12px;

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 70px 50px 25px 25px;
			position: relative;

			.service-button {
				position: absolute;
				top: 16px;
				right: 12px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					fill: @color1;
					height: 20px;
					min-width: 20px;
					max-width: 20px;
					transform: rotate(45deg);
					transition: all 0.3s;
				}

				&:hover {
					svg {
						transform: rotate(135deg);
						fill: @color2;
					}
				}
			}

			.service-content {
				.description {
					margin-top: 2rem;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.breadcrumb-wrapper {
	margin-top: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #ccc;

	.breadcrumb {
		margin: 0;

		li {
			display: none;
			width: 100%;

			&:before {
				display: none;
			}

			a {
				display: flex;
				align-items: center;
				padding: 5px 15px;
				border-radius: 20px;
				background: #f7f7f7;
				width: 100%;

				&:before {
					content: '';
					margin-right: 5px;
					transform: rotate(180deg);
					display: block;
					height: 10px;
					width: 10px;
					background: url('../img/icons/right-chevron.svg') center;
					background-size: cover;
				}

				span {
					transform: translateY(2px);
					font-size: 1rem;
					color: @color1;
				}
			}

			& + li {
				padding-left: 0;
			}

			&:nth-last-child(2) {
				display: block;
			}
		}
	}

	& + section.section-welcome {
		margin-top: 1rem;
	}
}

.container {
	padding: 0 @container-padding;

	&.no-padding {
		padding: 0;
		max-width: 100%;
	}
}

.heading-h1 {
	font-size: 1.5rem;
	color: @color1;
	font-family: "tt_commons_boldbold";
}

.heading-h2 {
	font-size: 1.3rem;
	color: @color1;
	font-family: "tt_commons_boldbold";
}

.paragraph {
	font-size: 1rem;
	color: #333;
	line-height: 1.2rem;
}

.input-service {
	margin-top: 10px;
	display: inline-block;
	width: 100%;

	.service-flexbox-wrapper {
		display: flex;
		width: 100%;
		align-items: center;

		.checkbox-wrapper {
			margin-top: 0 !important;
			margin-left: 0rem;
		}

		.service-inner {
			width: 100%;
			margin-top: 0.5rem;

			& + .service-inner {
				margin-left: 0.5rem;			
			}
		}
	}

	.service-inner {
		display: flex;
		border-radius: 70px;
		background: #fff;
		overflow: hidden;
		box-shadow: 3px 5px 15px 0px rgba(0, 0, 0, 0.1);
		position: relative;

		&.textarea {
			border-radius: 20px;
		}

		.inner-icon {
			height: 40px;
			min-width: 40px;
			max-width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			img, svg {
				height: 20px;
				width: 20px;
				fill: @color1;
			}

			& + input {
				padding-left: 0;
			}

			& + .select2-hidden-accessible {
				& + .select2 {
					padding-left: 0;
				}
			}
		}

		&.inner-value {
			background: #e1e1e1;
			box-shadow: none;
		}
	}

	label {
		display: block;
		font-size: 0.7rem;
		padding-left: 17px;
	}

	.value {
		display: block;
		min-height: 40px;
		width: 100%;
		padding-right: 10px;
		padding-top: 4px;
		line-height: 1.2rem;
		font-size: 1rem;
		color: @color1;
		display: flex;
		align-items: center;
		padding-left: 1rem;

		a {
			text-decoration: underline;
			color: inherit;
		}
	}

	input {
		padding-left: 1rem;
		height: 40px;
		width: 100%;
		border: 1px solid #f9f9f9;
		padding-top: 4px;
		border-radius: 50px;
		outline: none;
		padding-right: 10px;
		font-size: 1rem;
		color: @color1;
	}

	textarea {
		padding-left: 1rem;
		height: 100px;
		width: 100%;
		border: none;
		padding-top: 12px;
		outline: none;
		border-radius: 20px;
		padding-right: 10px;
		font-size: 1rem;
		color: @color1;
	}

	.input-alert {
		padding-left: 17px;
		margin-top: 7px;
		font-size: 0.7rem;
		color: #dc3545;
	}

	.select2 {
		padding-left: 1rem;
		width: 100% !important;
		overflow: hidden;

		.select2-selection {
			border: none;
			height: 40px;
			border-radius: 50px;

			.select2-selection__rendered {
				height: 40px;
				padding: 8px 20px 0 0;
				color: @color1;
				font-size: 1rem;
			}

			.select2-selection__arrow {
				height: 40px;

				b {
					margin-left: -7px;
				}
			}
		}
	}

	&.label-tooltip {
		label {
			&:after {
				content: '?';
				display: inline-block;
				height: 0.8rem;
				min-width: 0.8rem;
				max-width: 0.8rem;
				border-radius: 50%;
				background: @color1;
				color: #fff;
				font-size: 0.7rem;
				margin-left: 0.5rem;
				padding-left: 0.23rem;
				transform: translateY(1px);
				font-family: "tt_commons_boldbold";
			}
		}
	}
}

.input-delete {
	position: absolute;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background: @color2;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	top: 50%;
	right: 5px;
	transform: translateY(-50%);
}

.checkbox-wrapper {
	margin-top: 10px;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: hidden;

	.radio-box {
		display: flex;
		align-items: center;
		font-size: 16px;
		text-transform: none;
		color: #000;
		text-align: left;
		transition: all 0.3s;

		&:before {
			content: "";
			min-width: 20px;
			max-width: 20px;
			transform: translateY(-2px);
			border-radius: 5px;
			height: 20px;
			color: #fff;
			display: block;
			padding-top: 1px;
			border: 3px solid #ddd;
			background: #fff;
			font-size: 0.5rem;
			margin-right: 7px;
			transition: all 0.3s;
		}
	}

	input {
		visibility: visible;
		opacity: 0;
		left: 0;
		position: absolute;
		width: auto !important;
	}

	&.white {
		.radio-box {
			color: #fff;
		}
	}

	&.small {
		.radio-box {
			font-size: 12px;
			line-height: 12px;
		}
	}

	&.primary {
		.radio-box {
			padding: 13px 10px 10px 13px;
			background: #f3f3f3;
			border-radius: 10px;
		}
	}

	& + .checkbox-wrapper {
		margin-top: 5px;
	}
}

.checkbox-wrapper > input:checked + .radio-box {
	&:before {
		background: @color1;
	}
}

.checkbox-wrapper.white > input:checked + .radio-box {
	&:before {
		background: @color2;
	}
}

.checkbox-wrapper.primary > input:checked + .radio-box {
	background: @color1;
	color: #fff;

	&:before {
		background: @color2;
		content: 'X';
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.swiper-offer {
	padding: 0 @container-padding;
	margin-top: 1rem;

	.swiper-slide {
		width: auto;
	}
}

.swiper-buttons, .swiper-gallery {
	padding: 0 @container-padding;
	margin-top: 0.5rem;
	position: relative;

	&:before {
		pointer-events: none;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 2000;
		width: @container-padding;
		background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
	}

	&:after {
		pointer-events: none;
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		z-index: 2000;
		width: 2 * @container-padding;
		background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
	}

	.swiper-slide {
		width: auto;

		.gallery-item {
			width: 120px;
			height: 95px;
			border-radius: 12px;
			display: block;
			overflow: hidden;
			position: relative;

			img {
				height: 100%;
				width: 100%;
				object-position: center;
				object-fit: cover;
			}

			.item-delete {
				position: absolute;
				height: 22px;
				width: 22px;
				border-radius: 50%;
				padding-top: 0.3rem;
				font-size: 0.8rem;
				background: @color2;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1.5px solid #fff;
				outline: none;
				top: 4px;
				right: 4px;
			}
		}
	}

	&.gray {
		&:before {
			background: linear-gradient(90deg, rgba(243,243,243,1) 0%, rgba(243,243,243,0) 100%);
		}

		&:after {
			background: linear-gradient(-90deg, rgba(243,243,243,1) 0%, rgba(243,243,243,0) 100%);
		}
	}
}

.offer-items-wrapper {
	.offer-item {
		margin-top: 1rem;
		width: 100%;

		&.item-square {
			width: 100%;

			.item-image {
				padding-top: 40%;
			}
		}
	}
}

.offer-item {
	margin-bottom: 15px;
	width: 240px;
	border-radius: 20px;
	padding-bottom: 7px;
	overflow: hidden;
	box-shadow: 3px 7px 11px 0px rgba(0, 0, 0, 0.08);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #fff;
	position: relative;

	.item-add-cover {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: @color1;
		background: #eee;
		z-index: 1;

		.add-plus {
			height: 50px;
			width: 50px;
			border-radius: 10px;
			color: #fff;
			font-size: 2rem;
			font-weight: 700;
			background: @color1;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.add-name {
			font-size: 0.9rem;
			margin-top: 1rem;
			font-family: "tt_commons_boldbold";
			color: @color1;
		}
	}

	.item-image {
		width: 100%;
		padding-top: 62%;
		position: relative;
		border-radius: 0 0 20px 20px;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}

		.image-info {
			position: absolute;
			top: 8px;
			left: 8px;
			padding: 10px 10px 7px 12px;
			background: @color2;
			border-radius: 17px 5px 5px 5px;

			.info-value {
				font-size: 1rem;
				line-height: 1rem;
				color: #fff;
				font-family: "tt_commons_boldbold";
			}

			.info-quantity {
				font-size: 0.9rem;
				line-height: 1rem;
				color: #fff;
			}
		}

		.image-price {
			position: absolute;
			bottom: 8px;
			left: 8px;
			padding: 10px 10px 8px 12px;
			background: @color2;
			border-radius: 5px 5px 5px 17px;

			.price-promotion {
				font-size: 1rem;
				line-height: 1rem;
				color: #fff;
				font-family: "tt_commons_boldbold";
			}

			.price-old {
				font-size: 1rem;
				line-height: 1rem;
				color: #fff;
				text-decoration: line-through;
			}
		}
	}

	.item-description {
		padding: 5px 8px;

		.description-name {
			margin-top: 3px;
			color: @color1;
			font-family: "tt_commonsmedium";
			font-size: 1.2rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.description-company {
			background: @color1;
			border-radius: 20px;
			color: #fff;
			display: inline-block;
			padding: 2px 8px 0px 8px;
			font-size: 0.7rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-family: "tt_commonsmedium";
		}

		.description-stars {
			display: flex;
			align-items: center;

			.stars-inner {
				width: 80px;
				height: 16px;
				position: relative;
				overflow: hidden;

				.inner-bar-background {
					position: absolute;
					top: 1%;
					left: 1%;
					height: 98%;
					width: 98%;
					background: #ddd;
				}

				.inner-bar {
					position: absolute;
					top: 1%;
					left: 1%;
					height: 98%;
					background: @color2;
				}

				.inner-mask {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
				}
			}

			.stars-number {
				margin-left: 10px;
				transform: translateY(3px);
				font-size: 0.9rem;
				color: #333;
			}
		}

		.description-info {
			margin-top: 5px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.info-item {
				font-size: 0.9rem;
				color: #333;
				transform: translateY(2px);

				span {
					color: #bbb;
				}
			}

			.info-divider {
				margin: 0 10px;
				height: 10px;
				width: 1px;
				border-left: 0.5px solid #ccc;
			}
		}

		.description-hours {
			margin-top: 2px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.hours-item {
				margin: 3px 3px 0 0;
				font-size: 0.8rem;
				background: #f3f3f3;
				border-radius: 20px;
				padding: 2px 6px 0px 6px;
				color: #333;
			}
		}
	}

	.btn-text {
		text-align: center;
		margin-top: 5px;
		width: 100%;
	}

	&.item-square {
		width: 200px;

		.item-image {
			padding-top: 100%;
		}
	}

	&.item-add {
		box-shadow: none;
	}

	&.item-hidden {
		margin: 0;
		padding: 0;
		visibility: hidden;
	}
}

.buttons-wrapper {
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&.center {
		justify-content: center;

		.btn {
			width: 100%;
		}
	}
}

.empty-alert {
	margin-top: 1rem;
	padding: 15px;
	border-radius: 20px;
	background: #f7f7f7;
	text-align: center;
	font-size: 1.1rem;
	font-family: 'tt_commonsdemibold';
	color: #666;

	svg {
		height: 35px;
		width: 35px;
		margin-bottom: 0.5rem;

		&.bell {
			fill: @color2;
			animation: shake 1.82s infinite cubic-bezier(.36,.07,.19,.97) both;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;

			@keyframes shake {
				10%, 90% {
					transform: translate3d(-1px, 0, 0);
				}

				20%, 80% {
					transform: translate3d(2px, 0, 0);
				}

				30%, 50%, 70% {
					transform: translate3d(-3px, 0, 0);
				}

				40%, 60% {
					transform: translate3d(3px, 0, 0);
				}
			}
		}
	}

	.alert-counter {
		background: @color1;
		color: #fff;
		width: 90px;
		height: 40px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 0.5rem auto;
	}

	.alert-inner-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		.checkbox-wrapper {
			& + .checkbox-wrapper {
				margin-top: 10px;
				margin-left: 10px;
			}
		}
	}

	&.red {
		background: @color2;
		color: #fff;

		svg {
			fill: #fff;
		}
	}
}

.table-responsive {
	.table {
		margin: 0;

		thead {
			tr {
				th {
					border: none;
					padding: 5px;
					font-size: 0.8rem;
					opacity: 0.6;
				}
			}
		}

		tbody {
			tr {
				.hidden-link-wrapper {
					display: none;
				}

				td {
					padding: 5px;
					vertical-align: middle;
					border: none;

					&:before {
						content: attr(data-elmts-heading);
						display: none;
					}

					.value {
						color: #000;
						font-size: 0.8rem;
						line-height: 0.8rem;

						a {
							color: @color2;
							text-decoration: underline;
							font-family: "tt_commonsdemibold";
						}

						&.green {
							color: olivedrab;
							font-family: "tt_commonsdemibold";
						}

						&.red {
							color: #c31515;
							font-family: "tt_commonsdemibold";
						}
					}

					.download-button {
						svg {
							height: 20px;
							width: 20px;
							fill: @color2;
						}
					}
				}

				&:nth-child(even) {
					background: #f3f3f3;
				}
			}
		}
	}
}

.form-service {
	width: 100%;
	max-width: 500px;

	.info {
		margin-top: 1rem;

		p {
			font-size: 0.7rem;
			padding-left: 17px;
			color: #000;
		}
	}

	.checkbox-wrapper {
		margin-top: 1rem;

		& + .checkbox-wrapper {
			margin-top: 0.2rem;
		}
	}
}

.header-bag-sidebar {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	padding: 10px;
	transition: all 0.4s;

	.sidebar-service-wrapper {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		right: 0;
		top: 0;
		padding: 10px;

		.sidebar-service {
			overflow: hidden;
			padding: 10px;
			border-radius: 10px;
			position: relative;
			background: #f3f3f3;
			min-height: 100%;
			width: 100%;
			max-width: 500px;
			transform: translateX(110%);
			transition: all 0.4s;

			@z-index-value: 222;

			.service-menu-wrapper {
				position: absolute;
				background: #f3f3f3;
				border-radius: 10px;
				padding: 10px;
				top: 0;
				left: 0;
				transition: all 0.6s;
				z-index: @z-index-value;
				height: 100vh;
				width: 100%;

				&.hidden {
					transform: translateX(100%);
					z-index: 1;
				}
			}

			.service-menu-wrapper + .service-menu-wrapper {
				z-index: @z-index-value * 2;
			}

			.service-menu {
				max-height: calc(~'100vh - 40px');

				.backlink {
					display: block;
					outline: none;
					padding: 15px 20px;
					width: 100%;
					background: #fff;
					border-radius: 10px;
					border: 0.5px solid #eee;
					transition: all 0.5s;

					.backlink-inner {
						display: flex;
						align-items: center;

						span {
							color: #000;
							font-size: 1rem;
							transform: translateY(1px);
						}

						img {
							margin-right: 15px;
							transform: rotate(180deg);
							height: 10px;
						}
					}

					&.backlink-close {
						position: absolute;
						top: 0;
						left: 0;
						background: #fff;

						&.hidden {
							display: none;
						}
					}

					&:hover {
						background: #999;
					}
				}

				.menu-heading {
					margin-top: 10px;
					font-size: 1rem;
					text-align: center;
					padding: 15px 20px;
					color: #fff;
				}

				.menu-currency-languages {
					margin-top: 10px;

					.currency-languages-wrapper {
						background: #fff;
						border-radius: 10px;
						border: 0.5px solid #eee;
						display: flex;
						align-items: center;
						padding: 15px 50px 15px 20px;

						.icon {
							fill: #000;
							height: 20px;
							min-width: 20px;
							max-width: 20px;
							margin-right: 20px;
						}

						ul {
							display: flex;
							align-items: center;

							li {
								button, a {
									display: block;
									padding: 0px 6px 1px 6px;
									border-radius: 3px;
									border: 1px solid #ddd;
									background: none;
									outline: none;

									img {
										height: 24px;
										width: 24px;
									}

									span {
										color: #000;
										font-size: 0.8rem;
									}
								}

								& + li {
									margin-left: 5px;
								}
							}
						}

						& + .currency-languages-wrapper {
							border-top: 0.5px solid #ddd;
						}
					}
				}

				.menu-list {
					list-style: none;
					background: #fff;
					overflow: hidden;
					border-radius: 10px;
					border: 0.5px solid #eee;
					margin-top: 10px;

					li {
						a, button {
							padding: 15px 50px 15px 20px;
							width: 100%;
							background: none;
							position: relative;
							border: none;
							outline: none;
							display: flex;
							align-items: center;
							transition: all 0.5s;

							.icon {
								fill: #000;
								height: 20px;
								min-width: 20px;
								max-width: 20px;
								margin-right: 10px;
							}

							span {
								color: #000;
								font-size: 1rem;
								transform: translateY(1px);
							}

							&.has-child {
								&:after {
									content: '';
									height: 10px;
									min-width: 10px;
									max-width: 10px;
									position: absolute;
									right: 20px;
									top: 50%;
									transform: translateY(-50%);
									background: url(../img/icons/right-chevron.svg) center;
									background-size: cover;
								}
							}

							&.highlighted {
								background: @color1;

								.icon {
									fill: #fff;
								}

								span {
									color: #fff;
								}

								&:hover {
									background: darken(@color1, 10%);
								}

								&.red {
									background: @color2;

									&:hover {
										background: darken(@color2, 10%);
									}
								}
							}

							&:hover {
								background: #999;
							}
						}

						&.active {
							a, button {
								background: @color1;

								span {
									color: #fff;
								}
							}
						}

						&:not(:last-child) {
							border-bottom: 0.5px solid #ddd;
						}
					}
				}

				.menu-filters-button:not(.hidden) + .menu-heading {
					border-top: none;
				}

				.menu-heading + .menu-list {
					border-top: 0.5px solid #ddd;
				}

				.heading-h1 {
					margin-top: 2rem;

					span {
						color: @color2;
					}
				}

				.paragraph {
					margin-top: 1rem;
				}

				.nav-tabs {
					margin: 0.5rem 0 0 0;
					border: none;
					display: flex;
					justify-content: space-between;

					.nav-item {
						width: 50%;

						.nav-link {
							width: 100%;
							padding: 10px 5px 7px 5px;
							background: #eee;
							color: #000;
							border-radius: 20px;
							font-family: "tt_commons_boldbold";
							font-size: 1rem;
							border: none;
							outline: none;

							&.active {
								background: @color1;
								color: #fff;
								border: none;
							}
						}
					}
				}

				.input-service {
					.service-inner {
						border: 0.5px solid #eee;
						box-shadow: none;
					}
				}

				.btn-clear {
					margin-top: 2rem;
					font-size: 1rem;
					font-family: "tt_commonsdemibold";
					color: @color1;
					padding: 0;
					border: none;
					background: none;
					width: 100%;
					outline: none;

					& + .btn {
						margin-top: 0.5rem;
					}
				}

				.btn {
					margin-top: 2rem;
					width: 100%;
				}
			}

			&.active {
				transform: translateX(0);
			}
		}
	}

	&.active {
		z-index: 555;
		visibility: visible;
		opacity: 1;
	}
}

.modal {
	.modal-dialog {
		.modal-content {
			border-radius: 20px;

			.modal-header {
				.btn-close {
					height: 0.7em;
					width: 0.7em;
					background-size: 50%;
				}
			}
		}
	}
}

.toast-container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 10001;

	.container-inner {
		padding: 0.8rem;
	}

	.toast {
		width: 100%;
		border-radius: 12px;

		& + .toast {
			margin-top: 0.5rem;
		}
	}
}

.cookies-alert {
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 888;
	width: calc(~'100% - 40px');
	background: rgba(0, 0, 0, 0.5);
	border-radius: 2px;
	padding: 20px 30px;
	transition: all 0.4s;

	.btn-close {
		position: absolute;
		top: 4px;
		right: 0;
		outline: none;
		box-shadow: none;
		height: auto;
		width: auto;
		transition: opacity 0.4s;
		border: none;
		background: none;
		position: absolute;
		background: none;
		padding: 10px;
		cursor: pointer;

		span {
			position: absolute;
			display: block;
			left: 0;
			top: 50%;
			width: 12px;
			height: 1px;
			background: #fff;

			&:nth-child(1) {
				transform: rotate(45deg) translateY(-50%);
			}

			&:nth-child(2) {
				transform: rotate(-45deg) translateY(-50%);
			}
		}

		&:after {
			content: none;
		}
	}

	p {
		font-size: 11px;
		font-weight: 300;
		color: #fff;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&.hidden {
		visibility: hidden;
		opacity: 0;
	}
}

nav.section-header {
	.header-top {
		padding: 8px 0;
		background: #f7f7f7;
	}

	.header-flexbox {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-logo {
			img {
				height: 31px;
			}
		}

		.flexbox-nav-wrapper {
			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before, &:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				.flexbox-nav {

				}
			}
		}

		.flexbox-inner {
			position: relative;

			.inner-user {
				display: flex;
				align-items: center;
				position: absolute;
				right: 0;
				top: 0;
				transform: translateY(-100%);

				span {
					font-size: 0.6rem;
					white-space: nowrap;
				}

				button, a {
					font-size: 0.6rem;
					padding: 0;
					border: none;
					outline: none;
					background: none;
					margin-left: 0.5rem;
					color: @color2;
					font-family: 'tt_commonsdemibold';
				}
			}

			ul {
				display: flex;
				align-items: center;

				li {
					a, button {
						display: flex;
						align-items: center;
						outline: none;
						padding: 0;
						border: none;
						background: none;

						.button-name {
							font-size: 0.7rem;
							font-family: 'tt_commonsdemibold';
							margin-right: 0.5rem;
							color: @color1;
							display: none;
						}

						.button-icon {
							height: 40px;
							width: 40px;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							background: #f7f7f7;
						}

						svg {
							height: 15px;
							width: 15px;
							fill: @color1;

							&.large {
								height: 17px;
								width: 17px;
							}
						}
					}

					& + li {
						margin-left: 6px;
					}
				}

				.slogan {
					margin-right: 0.5rem;
					font-size: 0.7rem;
					color: #fff;
				}
			}
		}

		.nav-button {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			
			.button-bar {
				height: 2px;
				width: 18px;
				background: @color1;
				border-radius: 10px;
				transition: all 0.5s;

				&:nth-child(2) {
					margin-top: 3px;
				}

				&:nth-child(3) {
					margin-top: 3px;
				}
			}

			&:hover, &:focus {

			}
		}
	}

	&.background-blue {
		background: @color1;
	}
}

section.section-welcome {
	.welcome-search-engine {
		z-index: 1;
		background: #f7f7f7;
		position: relative;
		margin-top: -40px;
		padding: 10px;
		border-radius: 20px;

		.search-engine-inner {
			padding: 10px;
			background: @color1;
			border-radius: 0 0 18px 18px;

			.inner-row {
				flex-wrap: wrap;
				display: flex;
				justify-content: space-between;

				> * {
					width: 48.7%;
				}

				.checkbox-wrapper {
					margin-top: 10px;
				}
			}
		}

		.heading-h1 {
			text-align: center;

			svg {
				fill: @color1;
				height: 1.2rem;
				width: 1.2rem;
				transform: translateY(-5px);
			}
		}

		.nav-tabs {
			margin: 0.5rem 0 0 0;
			border: none;
			display: flex;
			justify-content: space-between;

			.nav-item {
				width: 50%;

				.nav-link {
					width: 100%;
					padding: 10px 5px 7px 5px;
					background: #eee;
					color: #000;
					border-radius: 20px 20px 0 0;
					font-family: "tt_commons_boldbold";
					font-size: 1rem;
					border: none;
					outline: none;

					&.active {
						background: @color1;
						color: #fff;
						border: none;
					}
				}
			}
		}

		.btn {
			margin-top: 1rem;
			width: 100%;
		}
	}

	&.welcome-cta {
		margin-top: 2rem;

		.swiper {
			border-radius: 20px;

			.welcome-item {
				border-radius: 0;
			}
		}
	}
}

.welcome-item {
	position: relative;
	overflow: hidden;
	border-radius: 20px;

	.item-background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		img {
			object-position: center;
			object-fit: cover;
			height: 100%;
			width: 100%;
			position: relative;
		}

		.background-cover {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.65;
			display: none;
		}
	}

	.item-description {
		height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		color: #fff;
		position: relative;

		.description-heading {
			padding-bottom: 50px;

			* {
				display: block;
			}

			b {
				text-transform: lowercase;
				font-size: 1rem;
				font-family: "tt_commonsthin";
				line-height: 5rem;
			}

			i {
				margin-right: 30px;
				font-family: "tt_commons_boldbold";
				font-size: 5rem;
				line-height: 4rem;
				font-style: normal;
			}

			strong {
				margin-left: 72px;
				line-height: 2rem;
				font-family: "tt_commonsmedium";
				font-size: 2rem;
			}
		}

		.heading-h1 {
			color: #fff;
			max-width: 300px;
		}

		.btn {
			margin-top: 2rem;
		}
	}

	&.small {
		height: 200px;
	}
}

section.section-offer {
	padding-top: 2rem;

	.last-minute-heading {
		position: relative;
		text-align: center;

		.heading-background {
			width: 100%;
			width: 240px;
		}

		.heading-inner {
			width: 150px;
			height: 150px;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #fff;
			box-shadow: 3px 8px 15px 0px rgba(0, 0, 0, 0.1);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding-top: 10px;
			padding-left: 3px;

			i {
				color: @color1;
				margin-right: 30px * 0.6;
				font-family: "tt_commons_boldbold";
				font-size: 5rem * 0.6;
				line-height: 3rem * 0.6;
				font-style: normal;
				display: block;
			}

			strong {
				color: @color1;
				margin-left: 72px * 0.6;
				line-height: 2rem * 0.6;
				font-family: "tt_commonsmedium";
				font-size: 2rem * 0.6;
				display: block;
			}
		}
	}
}

section.last-minute-section-content {
	.content-inner {
		> .heading-h1 {
			&:first-child {
				margin-top: 2rem;
			}
		}

		.heading-filter-button {
			margin-top: 1rem;
			border: none;
			outline: none;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px 15px;
			border-radius: 20px;
			background: @color1;
			width: 100%;

			svg {
				margin-right: 10px;
				fill: #fff;
				height: 17px;
				min-width: 17px;
				max-width: 17px;
			}

			span {
				transform: translateY(1px);
				font-size: 1rem;
				color: #fff;
			}

			&.red {
				background: @color2;
			}
		}

		.heading-filter-info {
			margin-top: 0.5rem;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			p {
				margin-right: 10px;
				font-size: 0.7rem;

				&:not(:first-child) {
					font-weight: 600;
				}
			}
		}

		.inner-map {
			margin-top: 2rem;

			.map-service {
				width: 100%;
				height: 85vh;

				.gm-ui-hover-effect {
					outline: none;
				}
			}
		}
	}
}

section.section-zero-waste-info {
	margin-top: 2rem;
	padding: 2rem 0;
	background: @color1;
	position: relative;
	display: none;

	.info-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;

		.image-cover {
			background: linear-gradient(-90deg, rgba(22,48,93,0) 0%, rgba(22,48,93,1) 100%);
			position: absolute;
			top: 0;
			left: 0;
			width: 65%;
			height: 100%;
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: left center;
		}
	}

	.info-inner {
		width: 50%;
		position: relative;

		.inner-text {
			h2 {
				color: #fff;
			}

			p {
				margin-top: 1rem;
				color: #fff;
				max-width: 400px;
				font-size: 0.8rem;
			}

			.btn {
				margin-top: 2rem;
			}
		}
	}
}

section.section-zero-waste {
	margin-top: calc(2rem + (@zero-waste-heading-size / 2));
	padding-top: calc(2rem + (@zero-waste-heading-size / 2));
	padding-bottom: 2rem;
	position: relative;
	background: #f3f3f3;

	@zero-waste-heading-size: 240px;

	.zero-waste-heading {
		position: absolute;
		top: -110px;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;

		.heading-background {
			width: 100%;
			width: @zero-waste-heading-size;
		}

		.heading-inner {
			width: 150px;
			height: 150px;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #fff;
			box-shadow: 3px 8px 15px 0px rgba(0, 0, 0, 0.1);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding-top: 10px;
			padding-left: 3px;

			i {
				color: @color1;
				margin-right: 30px * 0.6;
				font-family: "tt_commons_boldbold";
				font-size: 5rem * 0.6;
				line-height: 3rem * 0.6;
				font-style: normal;
				display: block;
			}

			strong {
				color: @color1;
				margin-left: 72px * 0.6;
				line-height: 2rem * 0.6;
				font-family: "tt_commonsmedium";
				font-size: 2rem * 0.6;
				display: block;
			}
		}
	}
}

section.section-newsletter {
	padding-top: 2rem;

	.newsletter-inner {
		margin-top: 1rem;
		padding: 10px;
		border-radius: 20px;
		background: #f7f7f7;

		.btn {
			margin-top: 1rem;
			width: 100%;
		}
	}
}

section.oferta-section-content {
	margin: 1rem auto 0 auto;
	max-width: 800px;

	.content-sticky-item-wrapper {
		position: relative;

		.item-wrapper-button {
			position: sticky;
			bottom: 4px;
			left: 0;
			z-index: 222;
			margin-top: 1rem;

			.btn {
				width: 100%;
			}
		}
	}

	.content-inner {
		.inner-heading-wrapper {
			position: relative;
			padding-right: 60px;

			.heading-bookmark {
				top: 0;
				right: 0;
				height: 40px;
				width: 40px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #eee;
				border: none;
				padding: 0;
				outline: none;
				position: absolute;

				svg {
					fill: #333;
					height: 21px;
					width: 21px;
				}

				&.active {
					background: @color2;

					svg {
						fill: #fff;
					}
				}
			}
		}

		.inner-alert {
			margin-top: 1.2rem;
			padding: 15px;
			border-radius: 20px;
			background: #f7f7f7;
			text-align: center;

			.heading-h2 {
				color: @color2;
			}
		}

		.inner-info {
			padding: 10px 10px 7px 12px;
			background: @color2;
			border-radius: 17px;
			margin-bottom: 1rem;

			.info-value {
				font-size: 1rem;
				line-height: 1rem;
				color: #fff;
				font-family: "tt_commons_boldbold";
			}

			.info-quantity {
				font-size: 0.9rem;
				line-height: 1rem;
				color: #fff;
			}
		}

		.inner-company {
			border-top: 1px solid #ccc;
			margin-top: 1rem;
			padding-top: 1rem;

			.heading-h2 {
				font-family: 'tt_commonsregular';
				color: #333;
			}
		}

		.inner-description {
			margin-top: 0.5rem;

			.description-stars {
				display: flex;
				align-items: center;

				.stars-inner {
					width: 80px;
					height: 16px;
					position: relative;
					overflow: hidden;

					.inner-bar-background {
						position: absolute;
						top: 1%;
						left: 1%;
						height: 98%;
						width: 98%;
						background: #ddd;
					}

					.inner-bar {
						position: absolute;
						top: 1%;
						left: 1%;
						height: 98%;
						background: @color2;
					}

					.inner-mask {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
					}
				}

				.stars-number {
					margin-left: 10px;
					transform: translateY(3px);
					font-size: 0.9rem;
					color: #333;
				}
			}

			.description-info {
				margin-top: 5px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.info-item {
					font-size: 0.9rem;
					color: #333;
					transform: translateY(2px);

					a {
						color: @color2;
						transition: all 0.5s;

						&:hover {
							color: darken(@color2, 10%);
						}
					}

					span {
						color: #bbb;
					}
				}

				.info-divider {
					margin: 0 10px;
					height: 10px;
					width: 1px;
					border-left: 0.5px solid #ccc;
				}
			}

			.description-hours {
				margin-top: 2px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.hours-heading {
					margin: 3px 3px 0 0;
					font-size: 0.8rem;
					color: #333;
				}

				.hours-item {
					margin: 3px 3px 0 0;
					font-size: 0.8rem;
					background: #f3f3f3;
					border-radius: 20px;
					padding: 2px 6px 0px 6px;
					color: #333;

					&.red {
						background: @color2;
						color: #fff;
					}
				}
			}

			.description-text {
				margin-top: 1rem;
			}
		}

		.inner-form {
			margin-top: 1rem;
			background: #f7f7f7;
			position: relative;
			padding: 15px;
			border-radius: 20px;

			.heading-h1 {
				text-align: center;
			}

			.btn-text {
				display: block;
				text-align: center;
				width: 100%;
				margin-top: 1rem;

				& + .btn {
					margin-top: 0.5rem;
				}
			}

			.btn {
				margin-top: 1rem;
				width: 100%;
			}
		}

		.inner-register-cta {
			margin-top: 1rem;

			.btn {
				width: 100%;
			}
		}
	}

	.content-cta {
		.btn {
			width: 100%;
			margin-top: 1rem;
		}
	}

	.content-map {
		margin-top: 1rem;
		width: 100%;
		padding-top: 60%;
		overflow: hidden;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 20px;
		}
	}

	.content-stars {
		margin-top: 2rem;

		.stars-flexbox {
			display: flex;

			.star-service {
				margin-right: 2px;

				.star {
					background: url('../img/icons/star-fill.svg') center;
					background-size: cover;
					height: 17px;
					width: 17px;
					display: block;
					filter: grayscale(100%);
					opacity: 0.3;
					transition: all 0.5s;
					cursor: pointer;
				}

				input {
					visibility: hidden;
					position: absolute;
					width: auto;
				}

				&.hover, &.active {
					.star {
						filter: grayscale(0%);
						opacity: 1;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.stars-inner {
			display: flex;
			align-items: center;

			.inner-heading {
				font-size: 1rem;
				font-family: 'tt_commons_boldbold';
				color: @color1;
				margin-top: 4px;
				margin-left: 1rem;
			}
		}

		.btn {
			margin-top: 0.5rem;
		}
	}

	.content-note {
		margin-top: 1rem;
		padding-bottom: 1rem;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;

		.btn-text {
			margin-top: 1rem;
			display: block;
			text-align: center;
		}

		.heading-h1 {
			margin-top: 1rem;
		}

		.btn {
			width: 100%;
			margin-top: 1rem;
		}
	}
}

.content-opinions {
	border-top: 1px solid #eee;

	.opinion-service {
		margin-top: 1rem;
		padding: 15px;
		border-radius: 20px;
		background: #f7f7f7;

		.service-name {
			font-size: 1rem;
			font-family: 'tt_commonsdemibold';
			color: @color1;

			span {
				font-size: 0.8em;
				color: #777;
			}
		}

		.service-contact {
			margin-top: 0.5rem;

			a {
				font-size: 1rem;
				color: @color2;
				text-decoration: underline;
				display: block;
			}
		}

		.service-text {
			margin-top: 0.5rem;
		}

		.service-inner-wrapper {
			display: flex;
			align-items: center;

			.service-inner {
				width: 80px;
				height: 16px;
				position: relative;
				overflow: hidden;

				.inner-bar-background {
					position: absolute;
					top: 1%;
					left: 1%;
					height: 98%;
					width: 98%;
					background: #ddd;
				}

				.inner-bar {
					position: absolute;
					top: 1%;
					left: 1%;
					height: 98%;
					background: @color2;
				}

				.inner-mask {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
				}
			}

			.service-number {
				margin-left: 10px;
				transform: translateY(3px);
				font-size: 0.9rem;
				color: #333;
			}
		}

		.service-answer {
			padding-left: 15px;
			margin-top: 0.5rem;
			padding-top: 0.5rem;
			border-top: 1px solid #ccc;

			span {
				font-size: 0.6rem;
				color: #999;
			}

			p {
				font-size: 0.8rem;
			}
		}

		.service-text-editor {
			margin-top: 0.5rem;

			.input-service {
				label {
					padding-left: 0;
				}

				.service-inner {
					border-top-left-radius: 0;
				}
			}

			.btn {
				margin-top: 0.5rem;
			}
		}
	}

	.btn-text {
		margin-top: 1rem;
		display: block;
	}
}

section.blog-section-content {
	padding: 2rem 0 0 0;

	.content-search-engine {
		border-radius: 20px;
		background: #f3f3f3;
		text-align: center;
		max-width: 800px;
		padding: 15px;
		margin-top: 1rem;
		margin-bottom: 2rem;

		.form-service {
			text-align: left;
			max-width: 100%;

			.input-service {
				margin: 0;
			}

			.btn {
				margin-top: 1rem;
				height: 40px;
			}
		}
	}

	.news-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.news-service {
			margin-top: 5%;
			width: 49%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: relative;
			transition: all 0.4s;

			.service-image-wrapper {
				width: 100%;
				padding-top: 100%;
				border-radius: 15px;
				overflow: hidden;
				position: relative;
				display: block;
				transition: all 0.4s;
				position: relative;

				.image-cover {
					position: absolute;
					z-index: 121;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					background: @color1;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					transition: all 0.4s;
				}

				.service-image {
					text-align: center;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;

					img {
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						position: absolute;
						object-fit: cover;
						object-position: center;
					}
				}
			}

			.date {
				margin-top: 7px;
				font-size: 0.7rem;
				font-family: 'tt_commonsdemibold';
				color: #888;
				display: block;
			}

			.name {
				margin-top: 3px;
				color: #000;
				font-size: 1rem;
				line-height: 1.2rem;
				padding-right: 1rem;
				display: block;
				transition: all 0.4s;
			}

			&:hover {
				.service-image-wrapper {
					.image-cover {
						opacity: 0.4;
					}
				}
			}

			&.service-hidden {
				margin: 0 !important;
				padding: 0 !important;
				border: none !important;
				visibility: hidden;
				opacity: 0;
			}
		}
	}

	.tag-cloud {
		margin-top: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		p, a {
			margin-right: 10px;
			font-size: 0.7rem;

			&:not(:first-child) {
				font-weight: 600;
			}
		}

		a {
			font-family: 'tt_commonsdemibold';
			color: @color2;
		}
	}

	.btn-more-products-wrapper {
		margin-top: 2rem;

		.more-products-counter {
			margin-bottom: 1rem;

			.number {
				font-size: 12px;
				color: #000;
				margin-bottom: 10px;
			}

			.line {
				margin: 0 auto;
				width: 170px;
				height: 2px;
				background: #eee;
				position: relative;

				.line-inner {
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					background: #333;
				}
			}
		}
	}
}

section.static-page-section-content {
	padding: 2rem 0 0 0;
	max-width: 800px;
	margin: 0 auto;

	.content-inner {
		margin-top: 1rem;

		.inner-info {
			margin-top: 0.5rem;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.info-service {
				font-size: 0.8rem;
				margin-right: 1rem;

				a {
					color: @color2;
				}
			}
		}

		.inner-main-image {
			margin-top: 1rem;
			width: 100%;
			padding-top: 60%;
			overflow: hidden;
			border-radius: 20px;
			position: relative;

			img {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.inner-gallery {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.gallery-service {
				margin-top: calc(~'4% / 3');
				display: block;
				width: 24%;
				padding-top: 20%;
				position: relative;
				overflow: hidden;
				background: #f9f9f9;

				img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					top: 50%;
					left: 0;
					transform: translateY(-50%;);
				}

				&.service-hidden {
					margin-top: 0;
					padding-top: 0;
					opacity: 0;
					visibility: hidden;
				}
			}

			&.gallery-collapsed {
				margin-top: 0;
				padding-top: 0;
			}
		}

		.btn-gallery-more {
			margin-top: 30px;

			&.hidden {
				display: none;
			}
		}

		.inner-description {
			margin-top: 1rem;

			h1 {
				font-size: 1.5rem;
				color: @color1;
				font-family: "tt_commons_boldbold";
			}

			h2 {
				font-size: 1.3rem;
				color: @color1;
				font-family: "tt_commons_boldbold";
			}

			h3 {
				font-size: 1.15rem;
				color: @color1;
				font-family: "tt_commons_boldbold";
			}

			ul, ol {
				padding-left: 17px;
			}

			p, li {
				font-size: 1rem;
				color: #333;
				line-height: 1.3rem;
			}
		}

		.inner-steps {
			.step-service {
				margin-top: 1.5rem;
				display: block;

				.service-image {
					margin: 0 auto;
					background: #f9f9f9;
					border-radius: 50%;
					height: 130px;
					width: 130px;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					img {
						height: 30px;
					}

					svg {
						height: 55px;
					}

					.image-number {
						position: absolute;
						top: -10px;
						right: -10px;
						font-size: 2rem;
						font-family: 'tt_commonsdemibold';
						color: #000;
						opacity: 0.3;
					}
				}

				.service-name {
					text-align: center;
					max-width: 200px;
					margin: 0.7rem auto 0 auto;

					h3 {
						font-size: 1.2rem;
						font-family: 'tt_commonsdemibold';
						color: @color1;
					}

					p {
						margin-top: 0.2rem;
						font-size: 0.8rem;
						color: #000;
						line-height: 1rem;
					}
				}

				& + .step-service {
					margin-top: 2.5rem;
				}
			}
		}

		.accordion {
			margin-top: 1rem;
		}

		.inner-download {
			margin-top: 2rem;

			ul {
				li {
					a {
						display: flex;
						align-items: center;
						background: #f9f9f9;
						border-radius: 15px;
						padding: 10px;

						svg {
							height: 20px;
							min-width: 20px;
							max-width: 20px;
							fill: @color2;
							margin-right: 10px;
						}

						span {
							font-size: 1rem;
							transform: translateY(2px);
							color: #333;
							text-decoration: underline;
							transition: all 0.4s;
						}

						&:hover {
							span {
								color: @color1;
							}
						}
					}

					& + li {
						margin-top: 0.5rem;
					}
				}
			}
		}
	}
}

section.centrum-pomocy-section-content {
	padding-top: 2rem;

	.heading {
		text-align: center;
		margin-bottom: 1rem;

		p {
			font-size: 0.8rem;
			color: #000;
		}

		&.margin-top {
			margin-top: 2rem;
		}

		&.text-left {
			text-align: left;
		}
	}

	.content-search-engine {
		border-radius: 20px;
		background: #f3f3f3;
		text-align: center;
		max-width: 800px;
		margin: 0 auto;
		padding: 15px;
		margin-bottom: 2rem;

		.form-service {
			text-align: left;
			max-width: 100%;

			.input-service {
				margin: 0;
			}

			.btn {
				margin-top: 1rem;
				height: 40px;
			}
		}
	}

	.content-flexbox {
		margin-top: 2rem;
		display: flex;
		flex-wrap: wrap;

		.flexbox-service {
			width: 100%;
			padding: 2rem 0;
			position: relative;

			ul {
				margin-top: 25px;

				li {
					button, a {
						padding: 0 1rem 0 0;
						border: none;
						font-size: 0.9rem;
						font-weight: 500;
						color: #333;
						background: none;
						display: block;
						width: 100%;
						text-align: left;
						position: relative;
						outline: none;
						transition: all 0.3s;

						&:after {
							content: "";
							height: 7px;
							width: 7px;
							background: url("../img/icons/right-chevron.svg") center;
							background-size: cover;
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
						}

						&:hover {
							color: @color2;
						}
					}

					& + li {
						margin-top: 0.5rem;
						padding-top: 0.5rem;
						border-top: 0.5px dotted #ccc;
					}
				}
			}

			&:after {
				content: none;
				height: 100%;
				width: 1px;
				border-right: 1px dotted @color1;
				opacity: 0.4;
				position: absolute;
				top: 0;
				right: 0;
			}

			&:before {
				content: '';
				height: 1px;
				width: 100%;
				border-bottom: 1px dotted @color1;
				opacity: 0.4;
				position: absolute;
				top: 0;
				left: 0;
			}

			&:nth-child(3n) {
				&:after {
					// content: none;
				}
			}

			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				&:before {
					// content: none;
				}
			}
		}
	}

	.content-form-wrapper {
		background: #f9f9f9;
		margin-top: 2rem;
		padding: 2rem 0;

		.content-form {
			// display: flex;
			// align-items: center;
			// justify-content: center;

			.section-heading {
				text-align: left;
			}

			.form-inner {
				// margin-left: 100px;
				width: 700px;
				max-width: 100%;

				.btn {
					margin-top: 20px;
				}
			}
		}
	}

	.content-videos {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.video-service {
			margin-top: 2rem;
			width: 100%;
			padding-top: 100% * 0.66;
			border-radius: 20px;
			overflow: hidden;
			position: relative;

			iframe {
				height: 100% !important;
				width: 100% !important;
				position: absolute;
				top: 0;
				left: 0;
			}

			&.service-hidden {
				opacity: 0;
				visibility: hidden;
				padding: 0;
				margin: 0;
			}
		}
	}
}

.footer-ue {
	margin-top: 2rem;
	text-align: center;

	img {
		width: 100%;
		max-width: 600px;
	}
}

section.section-footer {
	margin-top: 2rem;
	padding-top: 2rem;
	position: relative;
	background: @color1;

	.footer-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.inner-service {
			margin-top: 1rem;
			text-align: center;
			width: 50%;		

			ul {
				.heading {
					white-space: nowrap;
					font-size: 1rem;
					color: #fff;
					font-family: "tt_commons_boldbold";
				}

				li {
					a {
						font-size: 1rem;
						color: rgba(255, 255, 255, 0.7);
						white-space: nowrap;
						transition: all 0.4s;

						&:hover {
							color: @color2;
						}
					}
				}

				li + li {
					margin-top: 5px;
				}
			}

			.social-media {
				margin-top: 5px;
				display: flex;
				align-items: center;
				justify-content: center;

				li {
					a {
						svg {
							height: 30px;
							width: 30px;
							fill: rgba(255, 255, 255, 0.7);
							transition: all 0.5s;

							&:hover {
								fill: @color2;
							}
						}
					}

					& + li {
						margin-top: 0;
					}

					&:not(:last-child) {
						margin-right: 4px;
					}
				}
			}

			address {
				margin-top: 5px;
				margin-bottom: 0;

				a {
					display: block;
					font-size: 1rem;
					color: rgba(255, 255, 255, 0.7);
					transition: all 0.5s;

					&:hover {
						color: @color2;
					}
				}

				p {
					margin-top: 15px;
					font-size: 0.8rem;
					color: rgba(255, 255, 255, 0.7);
				}
			}

			.btn {
				margin-top: 20px;
			}

			&:last-child {
				width: 100%;
			}
		}
	}

	.footer-credits {
		margin-top: 2rem;
		padding-bottom: 1rem;
		display: flex;
		flex-direction: column-reverse;

		.credits-cta {
			.cta-button {
				margin: 0 auto 1rem auto;
				display: flex;
				align-items: center;
				background: @color2;
				padding: 0.2rem;
				border-radius: 100px;
				max-width: 200px;

				.button-icon {
					min-width: 2.3rem;
					max-width: 2.3rem;
					height: 2.3rem;
					border-radius: 50%;
					background: #fff;
					display: flex;
					align-items: center;
					justify-content: center;

					svg {
						height: 1.4rem;
						width: 1.4rem;
						fill: @color1;
					}
				}

				span {
					font-size: 1rem;
					color: #fff;
					padding: 0 1rem;
					text-align: center;
					width: 100%;
				}
			}
		}

		.credits-copyright {
			text-align: center;

			.copyright-logo {
				.logo-image {
					img {
						height: 30px;
					}
				}
			}

			p {
				margin-top: 0.5rem;	
				font-size: 0.7rem;
				color: rgba(255, 255, 255, 0.7);

				a {
					color: inherit;
					transition: all 0.5s;

					&:hover {
						color: @color1;
					}
				}
			}
		}
	}
}

section.panel-klienta-logowanie-section-content {
	padding: 2rem 0 0 0;

	.content-login {
		display: flex;
		justify-content: center;

		.login-service {
			width: 100%;
			max-width: 500px;

			.heading {
				text-align: center;
				margin-bottom: 1rem;

				p {
					font-size: 0.8rem;
					color: #000;
				}
			}

			.info {
				margin-top: 1rem;

				p {
					font-size: 0.7rem;
					padding-left: 17px;
					color: #000;
				}
			}

			.checkbox-wrapper {
				margin-top: 1rem;
			}

			.social-media-login {
				margin-top: 2rem;
				border: none;
				padding: 10px;
				background: #f3f3f3;
				width: 100%;
				border-radius: 50px;
				outline: none;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s;

				svg {
					height: 25px;
					min-width: 25px;
					max-width: 25px;
					margin-right: 20px;
				}

				p {
					color: #000;
					transition: all 0.4s;
				}

				& + .social-media-login {
					margin-top: 0.5rem;
				}

				&:hover {
					background: #444;
					border-color: #444;

					p {
						color: #fff;
					}
				}
			}
		}
	}
}

section.panel-klienta-section-content {
	padding: 2rem 0 0 0;

	.heading {
		text-align: center;
		margin-bottom: 1rem;

		p {
			font-size: 0.8rem;
			color: #000;
		}

		svg {
			fill: @color1;
			height: 1.2rem;
			width: 1.2rem;
			transform: translateY(-5px);
		}
	}

	.content-quick-click {
		.quick-click-camera {
			width: 100%;
			padding-top: 100%;
			position: relative;
			background: #f9f9f9;
			border-radius: 20px;

			video {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
			}
		}
	}
}

section.panel-restauratora-section-content {
	padding: 2rem 0 0 0;

	.heading {
		text-align: center;
		margin-bottom: 1rem;

		p {
			font-size: 0.8rem;
			color: #000;
		}

		&.margin-top {
			margin-top: 2rem;
		}

		&.text-left {
			text-align: left;
		}
	}

	.content-dashboard {
		.dashboard-cards {
			.card-service {
				margin-top: 1rem;
				display: block;
				text-align: center;

				.service-image {
					padding: 50px 30px;
					border-radius: 20px;
				}

				.name {
					display: block;
					margin-top: 1rem;
					font-style: 1rem;
					line-height: 1rem;
					font-weight: 600;
					color: #000;
				}

				.address {
					font-size: 0.7rem;
					color: #000;
				}
			}
		}
	}

	.content-manager {
		.btn {
			margin-top: 0.5rem;
		}

		.manager-list {
			margin-top: 2rem;

			.list-service {
				position: relative;

				.name {
					font-size: 0.9rem;
				}

				.mail {
					font-size: 0.7rem;
					line-height: 0.7rem;
				}

				& + .list-service {
					margin-top: 0.5rem;
					padding-top: 0.5rem;
					border-top: 1px solid #ccc;
				}
			}
		}
	}

	.content-objects {
		.objects-wrapper {
			display: flex;
			justify-content: space-between;			
			flex-wrap: wrap;

			.object-service {
				margin-top: 1rem;
				width: 48%;
				border-radius: 15px;
				background: #f3f3f3;
				padding: 15px;
				text-align: center;

				.name {
					font-size: 0.9rem;
					color: #777;
				}

				.number {
					font-size: 1.2rem;
					color: #000;
					font-family: "tt_commonsdemibold";
				}
			}
		}
	}

	.content-plan {
		margin-top: 1rem;
		padding: 15px;
		border-radius: 20px;
		background: #f7f7f7;
		text-align: center;

		.btn {
			margin-top: 0.5rem;			
		}
	}

	.content-plan-disactive {
		margin-top: 1rem;
		text-align: center;
	}

	.content-balance {
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: 1px solid #ccc;
		text-align: center;

		.balance-value {
			font-size: 2rem;
		}
	}

	.content-packages {
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: 1px solid #ccc;
		text-align: center;

		.packages-info {
			margin-top: 1rem;
			font-size: 1.2rem;

			strong {
				font-family: "tt_commonsdemibold";
				color: @color2;
			}
		}

		.packages-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.package-service {
				margin-top: 1rem;
				width: 32%;
				border-radius: 15px;
				background: #f3f3f3;
				padding: 15px;

				.name {
					font-size: 1.2rem;
					color: @color1;

					b {
						font-family: "tt_commons_boldbold";
					}
				}

				.value {
					font-size: 0.8rem;
					color: @color1;
					font-family: "tt_commons_boldbold";
				}

				.price {
					margin-top: 0.5rem;
					font-size: 1.2rem;
					color: @color2;
					font-family: "tt_commonsdemibold";

					small {
						font-size: 0.7em;
						text-decoration: line-through;
					}
				}

				.promotion {
					font-size: 0.8rem;
					color: #000;
				}

				.btn {
					margin-top: 1rem;
					padding: 0;
				}
			}
		}
	}

	.content-plans {
		text-align: center;

		.plans-wrapper {
			.plan-service {
				width: 100%;
				position: relative;

				.service-box {
					display: block;
					margin-top: 1rem;
					border-radius: 15px;
					border: 1px solid #f3f3f3;
					background: #f3f3f3;
					padding: 15px;
					transition: all 0.5s;

					.name {
						font-size: 1.2rem;
						color: @color1;

						b {
							font-family: "tt_commons_boldbold";
						}
					}

					.info {
						font-size: 0.85rem;
						color: #000;

						a {
							text-decoration: underline;
						}
					}

					.price {
						margin-top: 0.5rem;
						font-size: 1.2rem;
						color: @color2;
						font-family: "tt_commonsdemibold";
					}

					.btn {
						margin-top: 1rem;
						padding: 0;
					}
				}

				input {
					visibility: visible;
					opacity: 0;
					left: 0;
					position: absolute;
					width: auto !important;
				}
			}

			.plan-service > input:checked + .service-box {
				background: #fff;
				border: 1px solid @color2;
			}
		}

		.form-service {
			text-align: left;
		}

		.heading-h2 {
			margin-top: 2rem;
		}
	}

	.content-quick-click {
		.quick-click-objects {
			display: flex;
			justify-content: space-between;			
			flex-wrap: wrap;

			.object-service {
				margin-top: 1rem;
				width: 48%;
				border-radius: 15px;
				background: #f3f3f3;
				padding: 15px;
				text-align: center;
				position: relative;

				.time {
					position: absolute;
					top: 5px;
					right: 5px;
					padding-top: 2px;
					padding-right: 2px;
					border-radius: 4px 12px 4px 4px;
					background: @color2;
					color: #fff;
					font-size: 0.8rem;
					letter-spacing: -0.03rem;
					font-family: "tt_commonsdemibold";
					height: 20px;
					width: 40px;
				}

				.name {
					font-size: 0.9rem;
					color: #777;
				}

				.number {
					font-size: 1.2rem;
					color: #000;
					font-family: "tt_commonsdemibold";
				}

				&.service-alert {
					display: block;
					animation: blink 1.0s linear infinite;
					position: relative;

					&:after {
						content: attr(data-elmts-alert);
						position: absolute;
						font-family: "tt_commonsdemibold";
						left: 50%;
						top: 3px;
						transform: translateX(-50%);
						font-size: 0.6rem;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: red;

					}

					@keyframes blink {
						0% { box-shadow: 0 0 15px green; }
						50% { box-shadow: 0 0 0; }
						100% { box-shadow: 0 0 15px green; }
					}
				}
			}
		}
	}

	.content-search-engine {
		border-radius: 15px;
		background: #f3f3f3;
		padding: 15px 15px 0 15px;
		text-align: center;
		margin-bottom: 2rem;

		.form-service {
			text-align: left;
		}
	}

	.preloader-wrapper {
		width: 50px;
		height: 50px;
		margin: 35px auto 50px auto;
	}

	#preloader {
		width: 50px;
		height: 50px;
		border: 2px solid black;
		border-radius: 0px;
		animation: preloader 4.5s infinite linear;

		&:after {
			content: '';
			width: 14px;
			height: 14px;
			background: black;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -7px;
			margin-top: -7px;
			border-radius: 20px;
			animation: preloader_after 4.5s infinite linear;
			transform: scale(0);
		}
	}

	@keyframes preloader {
		0%{ -webkit-transform:scale(0)}
		10%{ -webkit-transform:scale(1.3)}
		12%{ -webkit-transform:scale(1)}
		15%{ -webkit-transform:scale(1.3)}
		17%{ -webkit-transform:scale(1)}
		25%{ -webkit-transform:scale(1)}
		40%{ -webkit-transform:scale(1) rotate(180deg); border-radius:20px;}
		42%{ -webkit-transform:scale(1) rotate(180deg); border-radius:0px;}
		44%{ -webkit-transform:scale(1) rotate(180deg); border-radius:20px;}
		46%{ -webkit-transform:scale(1) rotate(180deg); border-radius:0px;}
		48%{ -webkit-transform:scale(1) rotate(180deg); border-radius:20px;}
		50%{ -webkit-transform:scale(1) rotate(180deg); border-radius:20px;}
		95%{ -webkit-transform:scale(1) rotate(180deg); border-radius:20px;}
		100%{ -webkit-transform:scale(0) rotate(180deg); border-radius:100px;}
	}

	@keyframes preloader_after {
		0%{ transform:scale(0); }
		45%{ transform:scale(0); }
		50%{ transform:scale(1);}
		55%{ transform:scale(1) translateY(-20px) translateX(-14px);}
		60%{ transform:scale(1) translateY(20px) translateX(14px);}
		65%{ transform:scale(1) translateY(-20px) translateX(14px);}
		70%{ transform:scale(1) translateY(20px) translateX(-14px);}
		75%{ transform:scale(1) translateY(-20px) translateX(14px);}
		80%{ transform:scale(1) translateY(20px) translateX(-14px);}
		85%{ transform:scale(1) translateY(-20px) translateX(-14px);}
		90%{ transform:scale(1) translateY(0px) translateX(0px);}
		95%{ transform:scale(1.5);}
		100%{ transform:scale(0);}
	}
}