@media (min-width: 430px) {
	.offer-items-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.offer-item {
			margin-top: 1rem;
			width: 48% !important;
		}

		.wrapper-buttons {
			width: 100%;
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (min-width: 576px) {
	.cookies-alert {
		bottom: 40px;
		left: 40px;
		width: 330px;
	}
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 768px) {
	.offer-items-wrapper {
		.offer-item {
			width: 32% !important;
		}
	}

	section.blog-section-content {
		.content-search-engine {
			.form-service {
				.btn {
					margin-top: 0;
				}
			}
		}

		.news-flexbox {
			.news-service {
				margin-top: 5%;
				width: 31%;
			}
		}
	}

	section.centrum-pomocy-section-content {
		.content-search-engine {
			.form-service {
				.btn {
					margin-top: 0;
				}
			}
		}

		.content-flexbox {
			.flexbox-service {
				width: 31%;
				padding: 2rem;

				&:after {
					content: '';
				}

				&:nth-child(3n) {
					&:after {
						content: none;
					}
				}

				&:nth-child(1), &:nth-child(2), &:nth-child(3) {
					&:before {
						content: none;
					}
				}
			}
		}

		.content-form-wrapper {
			.content-form {
				display: flex;
				align-items: center;
				justify-content: center;

				.form-inner {
					margin-left: 100px;
				}
			}
		}

		.content-videos {
			.video-service {
				width: 31%;
				padding-top: 31% * 0.66;

				iframe {
				}
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (min-width: 992px) {
	html {
		font-size: 17px;
	}

	.toast-container {
		.container-inner {
			display: flex;
			align-items: flex-end;
			flex-direction: column;
		}

		.toast {
			max-width: 400px;

			& + .toast {
				margin-top: 0.5rem;
			}
		}
	}

	.breadcrumb-wrapper {
		.breadcrumb {
			li {
				display: block;
				width: auto;
				font-size: 0.7rem;

				&:before {
					display: block;
				}

				a {
					display: inline-block;
					padding: 0;
					border-radius: 0;
					background: none;
					width: auto;

					&:before {
						content: none;
					}

					span {
						transform: translateY(0);
						font-size: 0.7rem;
					}
				}

				& + li {
					padding-left: 0.5rem;
				}

				&:nth-last-child(2) {
					display: block;
				}
			}
		}

		& + section.section-welcome {
			margin-top: 1rem;
		}
	}

	.offer-items-wrapper {
		.offer-item {
			width: 24% !important;
		}
	}

	nav.section-header {
		background: #f9f9f9;

		.header-flexbox {
			padding: 25px 0;

			.flexbox-logo {
				img {
					height: 40px;
				}
			}

			.flexbox-inner {
				ul {
					li {
						button {
							.button-name {
								display: block;
							}

							.button-icon {
								background: #fff;
							}
						}
					}
				}
			}
		}

		&.background-white {
			background: #fff;

			.header-flexbox {
				.flexbox-inner {
					ul {
						li {
							button {
								.button-icon {
									background: #f7f7f7;
								}
							}
						}
					}
				}
			}
		}

		&.background-blue {
			background: @color1;
		}
	}

	section.section-welcome {
		position: relative;

		.container {
			max-width: 100%;
		}

		.swiper-welcome {
			border-radius: 20px 20px 0 0;
		}

		.welcome-item {
			border-radius: 20px 20px 0 0;

			.item-description {
				height: 300px;
			}
		}

		.welcome-search-engine {
			margin: 0;
			width: 100%;
			border-radius: 0 0 20px 20px;

			.search-engine-inner {
				.inner-row-wrapper {
					display: flex;
					align-items: center;

					&.width-100 {
						justify-content: space-between;

						> .input-service {
							width: 100%;

							& + .inner-row {
								margin-left: 1rem;
							}
						}

						.inner-row {
							width: 100%;
							flex-wrap: nowrap;

							> * {
								width: 100%;

								& + * {
									margin-left: 1rem;
								}
							}

							& + .inner-row {
								margin-left: 1rem;
							}

							&.w-200 {
								width: 200%;
							}

							& + .input-service {
								margin-left: 1rem;
							}
						}
					}

					&.width-auto {
						.inner-row {
							width: auto;

							> * {
								width: auto;

								& + * {
									margin-left: 1rem;
								}
							}

							& + .inner-row {
								margin-left: 1rem;
							}
						}
					}
				}

			}

			.heading-h1 {
				text-align: center;
			}
		}

		&.welcome-offer {
			max-width: 800px;
			margin: 1rem auto 0 auto;

			.welcome-item {
				border-radius: 20px;
			}
		}
	}

	section.section-offer {
		.last-minute-heading {
			display: none;
		}
	}

	section.section-zero-waste-info {
		display: block;
	}

	section.section-zero-waste {
		margin-top: 0;
		padding-top: 2rem;

		.zero-waste-heading {
			display: none;
		}
	}

	section.last-minute-section-content {
		.content-inner {
			.inner-filter-wrapper {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}

			.heading-filter-button {
				width: auto;
				margin-right: 2rem;
			}

			.heading-filter-info {
				margin-top: 1rem;
			}
		}
	}

	section.section-newsletter {
		padding-top: 2rem;
		text-align: center;

		.newsletter-inner {
			max-width: 500px;
			margin: 1rem auto 0 auto;
		}
	}

	section.section-footer {
		.footer-inner {
			.inner-service {
				width: auto;

				&:last-child {
					width: auto;
				}
			}
		}

		.footer-credits {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.credits-cta {
				display: flex;
				align-items: center;

				.cta-button {
					margin: 0;

					& + .cta-button {
						margin-left: 1rem;
					}
				}
			}

			.credits-copyright {
				text-align: left;
				display: flex;
				align-items: center;

				p {
					margin-top: 0;
					margin-left: 1.5rem;
				}
			}
		}
	}

	section.static-page-section-content {
		.content-inner {
			.inner-steps {
				display: flex;
				justify-content: space-between;

				.step-service {
					padding: 0 10px;

					& + .step-service {
						margin-top: 1.5rem;
					}
				}
			}
		}
	}

	section.panel-klienta-section-content {
		width: 500px;
		max-width: 100%;
		margin: 0 auto;

		.content-inner-wrapper {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;

			.inner-wrapper-sidebar {
				width: 30%;
			}

			.inner-wrapper-body {
				width: 65%;
			}
		}
	}

	section.panel-restauratora-section-content {
		width: 500px;
		max-width: 100%;
		margin: 0 auto;

		.content-inner-wrapper {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;

			.inner-wrapper-sidebar {
				width: 30%;
			}

			.inner-wrapper-body {
				width: 65%;

				.heading-h1 {
					margin-top: 1rem;
				}

				.swiper {
					padding: 0 !important;
				}
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (min-width: 1200px) {
	.offer-items-wrapper {
		.offer-item {
			width: 19% !important;
		}
	}

	section.blog-section-content {
		.news-flexbox {
			.news-service {
				margin-top: 5%;
				width: 24%;
			}
		}
	}
}

// Extralarge devices (desktops, less than 1400px)
@media (min-width: 1400px) {
	section.section-welcome {
		.container {
			max-width: 1320px;
			position: relative;
		}
	}

	section.section-zero-waste-info {
		padding: 4rem 0;
	}
}








// Extralarge devices (desktops, less than 1400px)
@media (max-width: 1399.98px) {

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {

}