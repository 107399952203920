@font-face {
  font-family: "tt_commonsdemibold";
  src: url("../fonts/ttcommons-demibold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-demibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commonslight";
  src: url("../fonts/ttcommons-light-webfont.woff2") format("woff2"), url("../fonts/ttcommons-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commonsmedium";
  src: url("../fonts/ttcommons-medium-webfont.woff2") format("woff2"), url("../fonts/ttcommons-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commonsblack";
  src: url("../fonts/ttcommons-black-webfont.woff2") format("woff2"), url("../fonts/ttcommons-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commons_boldbold";
  src: url("../fonts/ttcommons-bold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commonsextrabold";
  src: url("../fonts/ttcommons-extrabold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commonsextralight";
  src: url("../fonts/ttcommons-extralight-webfont.woff2") format("woff2"), url("../fonts/ttcommons-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commonsregular";
  src: url("../fonts/ttcommons-regular-webfont.woff2") format("woff2"), url("../fonts/ttcommons-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_commonsthin";
  src: url("../fonts/ttcommons-thin-webfont.woff2") format("woff2"), url("../fonts/ttcommons-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "tt_commonsregular";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body .btn {
  border-radius: 50px;
  padding: 10px 30px 9px 30px;
  font-size: 1rem;
  font-family: "tt_commonsdemibold";
  border: none;
  outline: none;
  box-shadow: none !important;
  transition: all 0.5s;
}
body .btn.btn-default {
  background: #c72222;
  color: #fff;
}
body .btn.btn-default:hover {
  background: #9b1b1b;
}
body .btn.btn-blue {
  background: #16305d;
  color: #fff;
}
body .btn.btn-blue:hover {
  background: #0c1b34;
}
body .btn.btn-gray {
  background: #e9e9e9;
  color: #16305d;
}
body .btn.btn-gray:hover {
  background: #16305d;
  color: #fff;
}
body .btn.btn-small {
  font-size: 0.8rem;
  padding: 8px 22px 6px 22px;
}
body .btn-text {
  font-size: 1rem;
  font-family: "tt_commonsdemibold";
  color: #c72222;
}
body .btn-text.btn-blue {
  color: #16305d;
  background: none !important;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 70px 50px 25px 25px;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 16px;
  right: 12px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button svg {
  fill: #16305d;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.modal-service-wrapper .modal-service .service-inner .service-button:hover svg {
  transform: rotate(135deg);
  fill: #c72222;
}
.modal-service-wrapper .modal-service .service-inner .service-content .description {
  margin-top: 2rem;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.breadcrumb-wrapper {
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.breadcrumb-wrapper .breadcrumb {
  margin: 0;
}
.breadcrumb-wrapper .breadcrumb li {
  display: none;
  width: 100%;
}
.breadcrumb-wrapper .breadcrumb li:before {
  display: none;
}
.breadcrumb-wrapper .breadcrumb li a {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 20px;
  background: #f7f7f7;
  width: 100%;
}
.breadcrumb-wrapper .breadcrumb li a:before {
  content: '';
  margin-right: 5px;
  transform: rotate(180deg);
  display: block;
  height: 10px;
  width: 10px;
  background: url('../img/icons/right-chevron.svg') center;
  background-size: cover;
}
.breadcrumb-wrapper .breadcrumb li a span {
  transform: translateY(2px);
  font-size: 1rem;
  color: #16305d;
}
.breadcrumb-wrapper .breadcrumb li + li {
  padding-left: 0;
}
.breadcrumb-wrapper .breadcrumb li:nth-last-child(2) {
  display: block;
}
.breadcrumb-wrapper + section.section-welcome {
  margin-top: 1rem;
}
.container {
  padding: 0 12px;
}
.container.no-padding {
  padding: 0;
  max-width: 100%;
}
.heading-h1 {
  font-size: 1.5rem;
  color: #16305d;
  font-family: "tt_commons_boldbold";
}
.heading-h2 {
  font-size: 1.3rem;
  color: #16305d;
  font-family: "tt_commons_boldbold";
}
.paragraph {
  font-size: 1rem;
  color: #333;
  line-height: 1.2rem;
}
.input-service {
  margin-top: 10px;
  display: inline-block;
  width: 100%;
}
.input-service .service-flexbox-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}
.input-service .service-flexbox-wrapper .checkbox-wrapper {
  margin-top: 0 !important;
  margin-left: 0rem;
}
.input-service .service-flexbox-wrapper .service-inner {
  width: 100%;
  margin-top: 0.5rem;
}
.input-service .service-flexbox-wrapper .service-inner + .service-inner {
  margin-left: 0.5rem;
}
.input-service .service-inner {
  display: flex;
  border-radius: 70px;
  background: #fff;
  overflow: hidden;
  box-shadow: 3px 5px 15px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}
.input-service .service-inner.textarea {
  border-radius: 20px;
}
.input-service .service-inner .inner-icon {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-service .service-inner .inner-icon img,
.input-service .service-inner .inner-icon svg {
  height: 20px;
  width: 20px;
  fill: #16305d;
}
.input-service .service-inner .inner-icon + input {
  padding-left: 0;
}
.input-service .service-inner .inner-icon + .select2-hidden-accessible + .select2 {
  padding-left: 0;
}
.input-service .service-inner.inner-value {
  background: #e1e1e1;
  box-shadow: none;
}
.input-service label {
  display: block;
  font-size: 0.7rem;
  padding-left: 17px;
}
.input-service .value {
  display: block;
  min-height: 40px;
  width: 100%;
  padding-right: 10px;
  padding-top: 4px;
  line-height: 1.2rem;
  font-size: 1rem;
  color: #16305d;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.input-service .value a {
  text-decoration: underline;
  color: inherit;
}
.input-service input {
  padding-left: 1rem;
  height: 40px;
  width: 100%;
  border: 1px solid #f9f9f9;
  padding-top: 4px;
  border-radius: 50px;
  outline: none;
  padding-right: 10px;
  font-size: 1rem;
  color: #16305d;
}
.input-service textarea {
  padding-left: 1rem;
  height: 100px;
  width: 100%;
  border: none;
  padding-top: 12px;
  outline: none;
  border-radius: 20px;
  padding-right: 10px;
  font-size: 1rem;
  color: #16305d;
}
.input-service .input-alert {
  padding-left: 17px;
  margin-top: 7px;
  font-size: 0.7rem;
  color: #dc3545;
}
.input-service .select2 {
  padding-left: 1rem;
  width: 100% !important;
  overflow: hidden;
}
.input-service .select2 .select2-selection {
  border: none;
  height: 40px;
  border-radius: 50px;
}
.input-service .select2 .select2-selection .select2-selection__rendered {
  height: 40px;
  padding: 8px 20px 0 0;
  color: #16305d;
  font-size: 1rem;
}
.input-service .select2 .select2-selection .select2-selection__arrow {
  height: 40px;
}
.input-service .select2 .select2-selection .select2-selection__arrow b {
  margin-left: -7px;
}
.input-service.label-tooltip label:after {
  content: '?';
  display: inline-block;
  height: 0.8rem;
  min-width: 0.8rem;
  max-width: 0.8rem;
  border-radius: 50%;
  background: #16305d;
  color: #fff;
  font-size: 0.7rem;
  margin-left: 0.5rem;
  padding-left: 0.23rem;
  transform: translateY(1px);
  font-family: "tt_commons_boldbold";
}
.input-delete {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #c72222;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.checkbox-wrapper {
  margin-top: 10px;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
}
.checkbox-wrapper .radio-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: none;
  color: #000;
  text-align: left;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 20px;
  max-width: 20px;
  transform: translateY(-2px);
  border-radius: 5px;
  height: 20px;
  color: #fff;
  display: block;
  padding-top: 1px;
  border: 3px solid #ddd;
  background: #fff;
  font-size: 0.5rem;
  margin-right: 7px;
  transition: all 0.3s;
}
.checkbox-wrapper input {
  visibility: visible;
  opacity: 0;
  left: 0;
  position: absolute;
  width: auto !important;
}
.checkbox-wrapper.white .radio-box {
  color: #fff;
}
.checkbox-wrapper.small .radio-box {
  font-size: 12px;
  line-height: 12px;
}
.checkbox-wrapper.primary .radio-box {
  padding: 13px 10px 10px 13px;
  background: #f3f3f3;
  border-radius: 10px;
}
.checkbox-wrapper + .checkbox-wrapper {
  margin-top: 5px;
}
.checkbox-wrapper > input:checked + .radio-box:before {
  background: #16305d;
}
.checkbox-wrapper.white > input:checked + .radio-box:before {
  background: #c72222;
}
.checkbox-wrapper.primary > input:checked + .radio-box {
  background: #16305d;
  color: #fff;
}
.checkbox-wrapper.primary > input:checked + .radio-box:before {
  background: #c72222;
  content: 'X';
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-offer {
  padding: 0 12px;
  margin-top: 1rem;
}
.swiper-offer .swiper-slide {
  width: auto;
}
.swiper-buttons,
.swiper-gallery {
  padding: 0 12px;
  margin-top: 0.5rem;
  position: relative;
}
.swiper-buttons:before,
.swiper-gallery:before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2000;
  width: 12px;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.swiper-buttons:after,
.swiper-gallery:after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;
  width: 24px;
  background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.swiper-buttons .swiper-slide,
.swiper-gallery .swiper-slide {
  width: auto;
}
.swiper-buttons .swiper-slide .gallery-item,
.swiper-gallery .swiper-slide .gallery-item {
  width: 120px;
  height: 95px;
  border-radius: 12px;
  display: block;
  overflow: hidden;
  position: relative;
}
.swiper-buttons .swiper-slide .gallery-item img,
.swiper-gallery .swiper-slide .gallery-item img {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}
.swiper-buttons .swiper-slide .gallery-item .item-delete,
.swiper-gallery .swiper-slide .gallery-item .item-delete {
  position: absolute;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  padding-top: 0.3rem;
  font-size: 0.8rem;
  background: #c72222;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #fff;
  outline: none;
  top: 4px;
  right: 4px;
}
.swiper-buttons.gray:before,
.swiper-gallery.gray:before {
  background: linear-gradient(90deg, #f3f3f3 0%, rgba(243, 243, 243, 0) 100%);
}
.swiper-buttons.gray:after,
.swiper-gallery.gray:after {
  background: linear-gradient(-90deg, #f3f3f3 0%, rgba(243, 243, 243, 0) 100%);
}
.offer-items-wrapper .offer-item {
  margin-top: 1rem;
  width: 100%;
}
.offer-items-wrapper .offer-item.item-square {
  width: 100%;
}
.offer-items-wrapper .offer-item.item-square .item-image {
  padding-top: 40%;
}
.offer-item {
  margin-bottom: 15px;
  width: 240px;
  border-radius: 20px;
  padding-bottom: 7px;
  overflow: hidden;
  box-shadow: 3px 7px 11px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  position: relative;
}
.offer-item .item-add-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #16305d;
  background: #eee;
  z-index: 1;
}
.offer-item .item-add-cover .add-plus {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  background: #16305d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.offer-item .item-add-cover .add-name {
  font-size: 0.9rem;
  margin-top: 1rem;
  font-family: "tt_commons_boldbold";
  color: #16305d;
}
.offer-item .item-image {
  width: 100%;
  padding-top: 62%;
  position: relative;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
}
.offer-item .item-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.offer-item .item-image .image-info {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 10px 10px 7px 12px;
  background: #c72222;
  border-radius: 17px 5px 5px 5px;
}
.offer-item .item-image .image-info .info-value {
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  font-family: "tt_commons_boldbold";
}
.offer-item .item-image .image-info .info-quantity {
  font-size: 0.9rem;
  line-height: 1rem;
  color: #fff;
}
.offer-item .item-image .image-price {
  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 10px 10px 8px 12px;
  background: #c72222;
  border-radius: 5px 5px 5px 17px;
}
.offer-item .item-image .image-price .price-promotion {
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  font-family: "tt_commons_boldbold";
}
.offer-item .item-image .image-price .price-old {
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  text-decoration: line-through;
}
.offer-item .item-description {
  padding: 5px 8px;
}
.offer-item .item-description .description-name {
  margin-top: 3px;
  color: #16305d;
  font-family: "tt_commonsmedium";
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.offer-item .item-description .description-company {
  background: #16305d;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  padding: 2px 8px 0px 8px;
  font-size: 0.7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "tt_commonsmedium";
}
.offer-item .item-description .description-stars {
  display: flex;
  align-items: center;
}
.offer-item .item-description .description-stars .stars-inner {
  width: 80px;
  height: 16px;
  position: relative;
  overflow: hidden;
}
.offer-item .item-description .description-stars .stars-inner .inner-bar-background {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 98%;
  width: 98%;
  background: #ddd;
}
.offer-item .item-description .description-stars .stars-inner .inner-bar {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 98%;
  background: #c72222;
}
.offer-item .item-description .description-stars .stars-inner .inner-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.offer-item .item-description .description-stars .stars-number {
  margin-left: 10px;
  transform: translateY(3px);
  font-size: 0.9rem;
  color: #333;
}
.offer-item .item-description .description-info {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.offer-item .item-description .description-info .info-item {
  font-size: 0.9rem;
  color: #333;
  transform: translateY(2px);
}
.offer-item .item-description .description-info .info-item span {
  color: #bbb;
}
.offer-item .item-description .description-info .info-divider {
  margin: 0 10px;
  height: 10px;
  width: 1px;
  border-left: 0.5px solid #ccc;
}
.offer-item .item-description .description-hours {
  margin-top: 2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.offer-item .item-description .description-hours .hours-item {
  margin: 3px 3px 0 0;
  font-size: 0.8rem;
  background: #f3f3f3;
  border-radius: 20px;
  padding: 2px 6px 0px 6px;
  color: #333;
}
.offer-item .btn-text {
  text-align: center;
  margin-top: 5px;
  width: 100%;
}
.offer-item.item-square {
  width: 200px;
}
.offer-item.item-square .item-image {
  padding-top: 100%;
}
.offer-item.item-add {
  box-shadow: none;
}
.offer-item.item-hidden {
  margin: 0;
  padding: 0;
  visibility: hidden;
}
.buttons-wrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttons-wrapper.center {
  justify-content: center;
}
.buttons-wrapper.center .btn {
  width: 100%;
}
.empty-alert {
  margin-top: 1rem;
  padding: 15px;
  border-radius: 20px;
  background: #f7f7f7;
  text-align: center;
  font-size: 1.1rem;
  font-family: 'tt_commonsdemibold';
  color: #666;
}
.empty-alert svg {
  height: 35px;
  width: 35px;
  margin-bottom: 0.5rem;
}
.empty-alert svg.bell {
  fill: #c72222;
  animation: shake 1.82s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
.empty-alert .alert-counter {
  background: #16305d;
  color: #fff;
  width: 90px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.5rem auto;
}
.empty-alert .alert-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-alert .alert-inner-wrapper .checkbox-wrapper + .checkbox-wrapper {
  margin-top: 10px;
  margin-left: 10px;
}
.empty-alert.red {
  background: #c72222;
  color: #fff;
}
.empty-alert.red svg {
  fill: #fff;
}
.table-responsive .table {
  margin: 0;
}
.table-responsive .table thead tr th {
  border: none;
  padding: 5px;
  font-size: 0.8rem;
  opacity: 0.6;
}
.table-responsive .table tbody tr .hidden-link-wrapper {
  display: none;
}
.table-responsive .table tbody tr td {
  padding: 5px;
  vertical-align: middle;
  border: none;
}
.table-responsive .table tbody tr td:before {
  content: attr(data-elmts-heading);
  display: none;
}
.table-responsive .table tbody tr td .value {
  color: #000;
  font-size: 0.8rem;
  line-height: 0.8rem;
}
.table-responsive .table tbody tr td .value a {
  color: #c72222;
  text-decoration: underline;
  font-family: "tt_commonsdemibold";
}
.table-responsive .table tbody tr td .value.green {
  color: olivedrab;
  font-family: "tt_commonsdemibold";
}
.table-responsive .table tbody tr td .value.red {
  color: #c31515;
  font-family: "tt_commonsdemibold";
}
.table-responsive .table tbody tr td .download-button svg {
  height: 20px;
  width: 20px;
  fill: #c72222;
}
.table-responsive .table tbody tr:nth-child(even) {
  background: #f3f3f3;
}
.form-service {
  width: 100%;
  max-width: 500px;
}
.form-service .info {
  margin-top: 1rem;
}
.form-service .info p {
  font-size: 0.7rem;
  padding-left: 17px;
  color: #000;
}
.form-service .checkbox-wrapper {
  margin-top: 1rem;
}
.form-service .checkbox-wrapper + .checkbox-wrapper {
  margin-top: 0.2rem;
}
.header-bag-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  padding: 10px;
  transition: all 0.4s;
}
.header-bag-sidebar .sidebar-service-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  right: 0;
  top: 0;
  padding: 10px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service {
  overflow: hidden;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  background: #f3f3f3;
  min-height: 100%;
  width: 100%;
  max-width: 500px;
  transform: translateX(110%);
  transition: all 0.4s;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu-wrapper {
  position: absolute;
  background: #f3f3f3;
  border-radius: 10px;
  padding: 10px;
  top: 0;
  left: 0;
  transition: all 0.6s;
  z-index: 222;
  height: 100vh;
  width: 100%;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu-wrapper.hidden {
  transform: translateX(100%);
  z-index: 1;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu-wrapper + .service-menu-wrapper {
  z-index: 444;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu {
  max-height: calc(100vh - 40px);
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .backlink {
  display: block;
  outline: none;
  padding: 15px 20px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  border: 0.5px solid #eee;
  transition: all 0.5s;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .backlink .backlink-inner {
  display: flex;
  align-items: center;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .backlink .backlink-inner span {
  color: #000;
  font-size: 1rem;
  transform: translateY(1px);
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .backlink .backlink-inner img {
  margin-right: 15px;
  transform: rotate(180deg);
  height: 10px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .backlink.backlink-close {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .backlink.backlink-close.hidden {
  display: none;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .backlink:hover {
  background: #999;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-heading {
  margin-top: 10px;
  font-size: 1rem;
  text-align: center;
  padding: 15px 20px;
  color: #fff;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages {
  margin-top: 10px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper {
  background: #fff;
  border-radius: 10px;
  border: 0.5px solid #eee;
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 20px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper .icon {
  fill: #000;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin-right: 20px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul {
  display: flex;
  align-items: center;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li button,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li a {
  display: block;
  padding: 0px 6px 1px 6px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background: none;
  outline: none;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li button img,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li a img {
  height: 24px;
  width: 24px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li button span,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li a span {
  color: #000;
  font-size: 0.8rem;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper ul li + li {
  margin-left: 5px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-currency-languages .currency-languages-wrapper + .currency-languages-wrapper {
  border-top: 0.5px solid #ddd;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list {
  list-style: none;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
  border: 0.5px solid #eee;
  margin-top: 10px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button {
  padding: 15px 50px 15px 20px;
  width: 100%;
  background: none;
  position: relative;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  transition: all 0.5s;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a .icon,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button .icon {
  fill: #000;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin-right: 10px;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a span,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button span {
  color: #000;
  font-size: 1rem;
  transform: translateY(1px);
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a.has-child:after,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button.has-child:after {
  content: '';
  height: 10px;
  min-width: 10px;
  max-width: 10px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/icons/right-chevron.svg) center;
  background-size: cover;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a.highlighted,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button.highlighted {
  background: #16305d;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a.highlighted .icon,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button.highlighted .icon {
  fill: #fff;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a.highlighted span,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button.highlighted span {
  color: #fff;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a.highlighted:hover,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button.highlighted:hover {
  background: #0c1b34;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a.highlighted.red,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button.highlighted.red {
  background: #c72222;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a.highlighted.red:hover,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button.highlighted.red:hover {
  background: #9b1b1b;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li a:hover,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li button:hover {
  background: #999;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li.active a,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li.active button {
  background: #16305d;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li.active a span,
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li.active button span {
  color: #fff;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-list li:not(:last-child) {
  border-bottom: 0.5px solid #ddd;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-filters-button:not(.hidden) + .menu-heading {
  border-top: none;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .menu-heading + .menu-list {
  border-top: 0.5px solid #ddd;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .heading-h1 {
  margin-top: 2rem;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .heading-h1 span {
  color: #c72222;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .paragraph {
  margin-top: 1rem;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .nav-tabs {
  margin: 0.5rem 0 0 0;
  border: none;
  display: flex;
  justify-content: space-between;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .nav-tabs .nav-item {
  width: 50%;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .nav-tabs .nav-item .nav-link {
  width: 100%;
  padding: 10px 5px 7px 5px;
  background: #eee;
  color: #000;
  border-radius: 20px;
  font-family: "tt_commons_boldbold";
  font-size: 1rem;
  border: none;
  outline: none;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .nav-tabs .nav-item .nav-link.active {
  background: #16305d;
  color: #fff;
  border: none;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .input-service .service-inner {
  border: 0.5px solid #eee;
  box-shadow: none;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .btn-clear {
  margin-top: 2rem;
  font-size: 1rem;
  font-family: "tt_commonsdemibold";
  color: #16305d;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  outline: none;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .btn-clear + .btn {
  margin-top: 0.5rem;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service .service-menu .btn {
  margin-top: 2rem;
  width: 100%;
}
.header-bag-sidebar .sidebar-service-wrapper .sidebar-service.active {
  transform: translateX(0);
}
.header-bag-sidebar.active {
  z-index: 555;
  visibility: visible;
  opacity: 1;
}
.modal .modal-dialog .modal-content {
  border-radius: 20px;
}
.modal .modal-dialog .modal-content .modal-header .btn-close {
  height: 0.7em;
  width: 0.7em;
  background-size: 50%;
}
.toast-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10001;
}
.toast-container .container-inner {
  padding: 0.8rem;
}
.toast-container .toast {
  width: 100%;
  border-radius: 12px;
}
.toast-container .toast + .toast {
  margin-top: 0.5rem;
}
.cookies-alert {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 888;
  width: calc(100% - 40px);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 20px 30px;
  transition: all 0.4s;
}
.cookies-alert .btn-close {
  top: 4px;
  right: 0;
  outline: none;
  box-shadow: none;
  height: auto;
  width: auto;
  transition: opacity 0.4s;
  border: none;
  position: absolute;
  background: none;
  padding: 10px;
  cursor: pointer;
}
.cookies-alert .btn-close span {
  position: absolute;
  display: block;
  left: 0;
  top: 50%;
  width: 12px;
  height: 1px;
  background: #fff;
}
.cookies-alert .btn-close span:nth-child(1) {
  transform: rotate(45deg) translateY(-50%);
}
.cookies-alert .btn-close span:nth-child(2) {
  transform: rotate(-45deg) translateY(-50%);
}
.cookies-alert .btn-close:after {
  content: none;
}
.cookies-alert p {
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}
.cookies-alert p a {
  color: inherit;
  text-decoration: underline;
}
.cookies-alert.hidden {
  visibility: hidden;
  opacity: 0;
}
nav.section-header .header-top {
  padding: 8px 0;
  background: #f7f7f7;
}
nav.section-header .header-flexbox {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo img {
  height: 31px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-inner {
  position: relative;
}
nav.section-header .header-flexbox .flexbox-inner .inner-user {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
}
nav.section-header .header-flexbox .flexbox-inner .inner-user span {
  font-size: 0.6rem;
  white-space: nowrap;
}
nav.section-header .header-flexbox .flexbox-inner .inner-user button,
nav.section-header .header-flexbox .flexbox-inner .inner-user a {
  font-size: 0.6rem;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  margin-left: 0.5rem;
  color: #c72222;
  font-family: 'tt_commonsdemibold';
}
nav.section-header .header-flexbox .flexbox-inner ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-inner ul li a,
nav.section-header .header-flexbox .flexbox-inner ul li button {
  display: flex;
  align-items: center;
  outline: none;
  padding: 0;
  border: none;
  background: none;
}
nav.section-header .header-flexbox .flexbox-inner ul li a .button-name,
nav.section-header .header-flexbox .flexbox-inner ul li button .button-name {
  font-size: 0.7rem;
  font-family: 'tt_commonsdemibold';
  margin-right: 0.5rem;
  color: #16305d;
  display: none;
}
nav.section-header .header-flexbox .flexbox-inner ul li a .button-icon,
nav.section-header .header-flexbox .flexbox-inner ul li button .button-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f7f7f7;
}
nav.section-header .header-flexbox .flexbox-inner ul li a svg,
nav.section-header .header-flexbox .flexbox-inner ul li button svg {
  height: 15px;
  width: 15px;
  fill: #16305d;
}
nav.section-header .header-flexbox .flexbox-inner ul li a svg.large,
nav.section-header .header-flexbox .flexbox-inner ul li button svg.large {
  height: 17px;
  width: 17px;
}
nav.section-header .header-flexbox .flexbox-inner ul li + li {
  margin-left: 6px;
}
nav.section-header .header-flexbox .flexbox-inner ul .slogan {
  margin-right: 0.5rem;
  font-size: 0.7rem;
  color: #fff;
}
nav.section-header .header-flexbox .nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 18px;
  background: #16305d;
  border-radius: 10px;
  transition: all 0.5s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 3px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 3px;
}
nav.section-header.background-blue {
  background: #16305d;
}
section.section-welcome .welcome-search-engine {
  z-index: 1;
  background: #f7f7f7;
  position: relative;
  margin-top: -40px;
  padding: 10px;
  border-radius: 20px;
}
section.section-welcome .welcome-search-engine .search-engine-inner {
  padding: 10px;
  background: #16305d;
  border-radius: 0 0 18px 18px;
}
section.section-welcome .welcome-search-engine .search-engine-inner .inner-row {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
section.section-welcome .welcome-search-engine .search-engine-inner .inner-row > * {
  width: 48.7%;
}
section.section-welcome .welcome-search-engine .search-engine-inner .inner-row .checkbox-wrapper {
  margin-top: 10px;
}
section.section-welcome .welcome-search-engine .heading-h1 {
  text-align: center;
}
section.section-welcome .welcome-search-engine .heading-h1 svg {
  fill: #16305d;
  height: 1.2rem;
  width: 1.2rem;
  transform: translateY(-5px);
}
section.section-welcome .welcome-search-engine .nav-tabs {
  margin: 0.5rem 0 0 0;
  border: none;
  display: flex;
  justify-content: space-between;
}
section.section-welcome .welcome-search-engine .nav-tabs .nav-item {
  width: 50%;
}
section.section-welcome .welcome-search-engine .nav-tabs .nav-item .nav-link {
  width: 100%;
  padding: 10px 5px 7px 5px;
  background: #eee;
  color: #000;
  border-radius: 20px 20px 0 0;
  font-family: "tt_commons_boldbold";
  font-size: 1rem;
  border: none;
  outline: none;
}
section.section-welcome .welcome-search-engine .nav-tabs .nav-item .nav-link.active {
  background: #16305d;
  color: #fff;
  border: none;
}
section.section-welcome .welcome-search-engine .btn {
  margin-top: 1rem;
  width: 100%;
}
section.section-welcome.welcome-cta {
  margin-top: 2rem;
}
section.section-welcome.welcome-cta .swiper {
  border-radius: 20px;
}
section.section-welcome.welcome-cta .swiper .welcome-item {
  border-radius: 0;
}
.welcome-item {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}
.welcome-item .item-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.welcome-item .item-background img {
  object-position: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: relative;
}
.welcome-item .item-background .background-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.65;
  display: none;
}
.welcome-item .item-description {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: #fff;
  position: relative;
}
.welcome-item .item-description .description-heading {
  padding-bottom: 50px;
}
.welcome-item .item-description .description-heading * {
  display: block;
}
.welcome-item .item-description .description-heading b {
  text-transform: lowercase;
  font-size: 1rem;
  font-family: "tt_commonsthin";
  line-height: 5rem;
}
.welcome-item .item-description .description-heading i {
  margin-right: 30px;
  font-family: "tt_commons_boldbold";
  font-size: 5rem;
  line-height: 4rem;
  font-style: normal;
}
.welcome-item .item-description .description-heading strong {
  margin-left: 72px;
  line-height: 2rem;
  font-family: "tt_commonsmedium";
  font-size: 2rem;
}
.welcome-item .item-description .heading-h1 {
  color: #fff;
  max-width: 300px;
}
.welcome-item .item-description .btn {
  margin-top: 2rem;
}
.welcome-item.small {
  height: 200px;
}
section.section-offer {
  padding-top: 2rem;
}
section.section-offer .last-minute-heading {
  position: relative;
  text-align: center;
}
section.section-offer .last-minute-heading .heading-background {
  width: 100%;
  width: 240px;
}
section.section-offer .last-minute-heading .heading-inner {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 3px 8px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 3px;
}
section.section-offer .last-minute-heading .heading-inner i {
  color: #16305d;
  margin-right: 18px;
  font-family: "tt_commons_boldbold";
  font-size: 3rem;
  line-height: 1.8rem;
  font-style: normal;
  display: block;
}
section.section-offer .last-minute-heading .heading-inner strong {
  color: #16305d;
  margin-left: 43.2px;
  line-height: 1.2rem;
  font-family: "tt_commonsmedium";
  font-size: 1.2rem;
  display: block;
}
section.last-minute-section-content .content-inner > .heading-h1:first-child {
  margin-top: 2rem;
}
section.last-minute-section-content .content-inner .heading-filter-button {
  margin-top: 1rem;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 20px;
  background: #16305d;
  width: 100%;
}
section.last-minute-section-content .content-inner .heading-filter-button svg {
  margin-right: 10px;
  fill: #fff;
  height: 17px;
  min-width: 17px;
  max-width: 17px;
}
section.last-minute-section-content .content-inner .heading-filter-button span {
  transform: translateY(1px);
  font-size: 1rem;
  color: #fff;
}
section.last-minute-section-content .content-inner .heading-filter-button.red {
  background: #c72222;
}
section.last-minute-section-content .content-inner .heading-filter-info {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.last-minute-section-content .content-inner .heading-filter-info p {
  margin-right: 10px;
  font-size: 0.7rem;
}
section.last-minute-section-content .content-inner .heading-filter-info p:not(:first-child) {
  font-weight: 600;
}
section.last-minute-section-content .content-inner .inner-map {
  margin-top: 2rem;
}
section.last-minute-section-content .content-inner .inner-map .map-service {
  width: 100%;
  height: 85vh;
}
section.last-minute-section-content .content-inner .inner-map .map-service .gm-ui-hover-effect {
  outline: none;
}
section.section-zero-waste-info {
  margin-top: 2rem;
  padding: 2rem 0;
  background: #16305d;
  position: relative;
  display: none;
}
section.section-zero-waste-info .info-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}
section.section-zero-waste-info .info-image .image-cover {
  background: linear-gradient(-90deg, rgba(22, 48, 93, 0) 0%, #16305d 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 65%;
  height: 100%;
}
section.section-zero-waste-info .info-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left center;
}
section.section-zero-waste-info .info-inner {
  width: 50%;
  position: relative;
}
section.section-zero-waste-info .info-inner .inner-text h2 {
  color: #fff;
}
section.section-zero-waste-info .info-inner .inner-text p {
  margin-top: 1rem;
  color: #fff;
  max-width: 400px;
  font-size: 0.8rem;
}
section.section-zero-waste-info .info-inner .inner-text .btn {
  margin-top: 2rem;
}
section.section-zero-waste {
  margin-top: calc(2rem + (240px / 2));
  padding-top: calc(2rem + (240px / 2));
  padding-bottom: 2rem;
  position: relative;
  background: #f3f3f3;
}
section.section-zero-waste .zero-waste-heading {
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
section.section-zero-waste .zero-waste-heading .heading-background {
  width: 100%;
  width: 240px;
}
section.section-zero-waste .zero-waste-heading .heading-inner {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 3px 8px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 3px;
}
section.section-zero-waste .zero-waste-heading .heading-inner i {
  color: #16305d;
  margin-right: 18px;
  font-family: "tt_commons_boldbold";
  font-size: 3rem;
  line-height: 1.8rem;
  font-style: normal;
  display: block;
}
section.section-zero-waste .zero-waste-heading .heading-inner strong {
  color: #16305d;
  margin-left: 43.2px;
  line-height: 1.2rem;
  font-family: "tt_commonsmedium";
  font-size: 1.2rem;
  display: block;
}
section.section-newsletter {
  padding-top: 2rem;
}
section.section-newsletter .newsletter-inner {
  margin-top: 1rem;
  padding: 10px;
  border-radius: 20px;
  background: #f7f7f7;
}
section.section-newsletter .newsletter-inner .btn {
  margin-top: 1rem;
  width: 100%;
}
section.oferta-section-content {
  margin: 1rem auto 0 auto;
  max-width: 800px;
}
section.oferta-section-content .content-sticky-item-wrapper {
  position: relative;
}
section.oferta-section-content .content-sticky-item-wrapper .item-wrapper-button {
  position: sticky;
  bottom: 4px;
  left: 0;
  z-index: 222;
  margin-top: 1rem;
}
section.oferta-section-content .content-sticky-item-wrapper .item-wrapper-button .btn {
  width: 100%;
}
section.oferta-section-content .content-inner .inner-heading-wrapper {
  position: relative;
  padding-right: 60px;
}
section.oferta-section-content .content-inner .inner-heading-wrapper .heading-bookmark {
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  border: none;
  padding: 0;
  outline: none;
  position: absolute;
}
section.oferta-section-content .content-inner .inner-heading-wrapper .heading-bookmark svg {
  fill: #333;
  height: 21px;
  width: 21px;
}
section.oferta-section-content .content-inner .inner-heading-wrapper .heading-bookmark.active {
  background: #c72222;
}
section.oferta-section-content .content-inner .inner-heading-wrapper .heading-bookmark.active svg {
  fill: #fff;
}
section.oferta-section-content .content-inner .inner-alert {
  margin-top: 1.2rem;
  padding: 15px;
  border-radius: 20px;
  background: #f7f7f7;
  text-align: center;
}
section.oferta-section-content .content-inner .inner-alert .heading-h2 {
  color: #c72222;
}
section.oferta-section-content .content-inner .inner-info {
  padding: 10px 10px 7px 12px;
  background: #c72222;
  border-radius: 17px;
  margin-bottom: 1rem;
}
section.oferta-section-content .content-inner .inner-info .info-value {
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  font-family: "tt_commons_boldbold";
}
section.oferta-section-content .content-inner .inner-info .info-quantity {
  font-size: 0.9rem;
  line-height: 1rem;
  color: #fff;
}
section.oferta-section-content .content-inner .inner-company {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}
section.oferta-section-content .content-inner .inner-company .heading-h2 {
  font-family: 'tt_commonsregular';
  color: #333;
}
section.oferta-section-content .content-inner .inner-description {
  margin-top: 0.5rem;
}
section.oferta-section-content .content-inner .inner-description .description-stars {
  display: flex;
  align-items: center;
}
section.oferta-section-content .content-inner .inner-description .description-stars .stars-inner {
  width: 80px;
  height: 16px;
  position: relative;
  overflow: hidden;
}
section.oferta-section-content .content-inner .inner-description .description-stars .stars-inner .inner-bar-background {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 98%;
  width: 98%;
  background: #ddd;
}
section.oferta-section-content .content-inner .inner-description .description-stars .stars-inner .inner-bar {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 98%;
  background: #c72222;
}
section.oferta-section-content .content-inner .inner-description .description-stars .stars-inner .inner-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
section.oferta-section-content .content-inner .inner-description .description-stars .stars-number {
  margin-left: 10px;
  transform: translateY(3px);
  font-size: 0.9rem;
  color: #333;
}
section.oferta-section-content .content-inner .inner-description .description-info {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.oferta-section-content .content-inner .inner-description .description-info .info-item {
  font-size: 0.9rem;
  color: #333;
  transform: translateY(2px);
}
section.oferta-section-content .content-inner .inner-description .description-info .info-item a {
  color: #c72222;
  transition: all 0.5s;
}
section.oferta-section-content .content-inner .inner-description .description-info .info-item a:hover {
  color: #9b1b1b;
}
section.oferta-section-content .content-inner .inner-description .description-info .info-item span {
  color: #bbb;
}
section.oferta-section-content .content-inner .inner-description .description-info .info-divider {
  margin: 0 10px;
  height: 10px;
  width: 1px;
  border-left: 0.5px solid #ccc;
}
section.oferta-section-content .content-inner .inner-description .description-hours {
  margin-top: 2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.oferta-section-content .content-inner .inner-description .description-hours .hours-heading {
  margin: 3px 3px 0 0;
  font-size: 0.8rem;
  color: #333;
}
section.oferta-section-content .content-inner .inner-description .description-hours .hours-item {
  margin: 3px 3px 0 0;
  font-size: 0.8rem;
  background: #f3f3f3;
  border-radius: 20px;
  padding: 2px 6px 0px 6px;
  color: #333;
}
section.oferta-section-content .content-inner .inner-description .description-hours .hours-item.red {
  background: #c72222;
  color: #fff;
}
section.oferta-section-content .content-inner .inner-description .description-text {
  margin-top: 1rem;
}
section.oferta-section-content .content-inner .inner-form {
  margin-top: 1rem;
  background: #f7f7f7;
  position: relative;
  padding: 15px;
  border-radius: 20px;
}
section.oferta-section-content .content-inner .inner-form .heading-h1 {
  text-align: center;
}
section.oferta-section-content .content-inner .inner-form .btn-text {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}
section.oferta-section-content .content-inner .inner-form .btn-text + .btn {
  margin-top: 0.5rem;
}
section.oferta-section-content .content-inner .inner-form .btn {
  margin-top: 1rem;
  width: 100%;
}
section.oferta-section-content .content-inner .inner-register-cta {
  margin-top: 1rem;
}
section.oferta-section-content .content-inner .inner-register-cta .btn {
  width: 100%;
}
section.oferta-section-content .content-cta .btn {
  width: 100%;
  margin-top: 1rem;
}
section.oferta-section-content .content-map {
  margin-top: 1rem;
  width: 100%;
  padding-top: 60%;
  overflow: hidden;
  position: relative;
}
section.oferta-section-content .content-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
section.oferta-section-content .content-stars {
  margin-top: 2rem;
}
section.oferta-section-content .content-stars .stars-flexbox {
  display: flex;
}
section.oferta-section-content .content-stars .stars-flexbox .star-service {
  margin-right: 2px;
}
section.oferta-section-content .content-stars .stars-flexbox .star-service .star {
  background: url('../img/icons/star-fill.svg') center;
  background-size: cover;
  height: 17px;
  width: 17px;
  display: block;
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 0.5s;
  cursor: pointer;
}
section.oferta-section-content .content-stars .stars-flexbox .star-service input {
  visibility: hidden;
  position: absolute;
  width: auto;
}
section.oferta-section-content .content-stars .stars-flexbox .star-service.hover .star,
section.oferta-section-content .content-stars .stars-flexbox .star-service.active .star {
  filter: grayscale(0%);
  opacity: 1;
}
section.oferta-section-content .content-stars .stars-flexbox .star-service:last-child {
  margin-right: 0;
}
section.oferta-section-content .content-stars .stars-inner {
  display: flex;
  align-items: center;
}
section.oferta-section-content .content-stars .stars-inner .inner-heading {
  font-size: 1rem;
  font-family: 'tt_commons_boldbold';
  color: #16305d;
  margin-top: 4px;
  margin-left: 1rem;
}
section.oferta-section-content .content-stars .btn {
  margin-top: 0.5rem;
}
section.oferta-section-content .content-note {
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
section.oferta-section-content .content-note .btn-text {
  margin-top: 1rem;
  display: block;
  text-align: center;
}
section.oferta-section-content .content-note .heading-h1 {
  margin-top: 1rem;
}
section.oferta-section-content .content-note .btn {
  width: 100%;
  margin-top: 1rem;
}
.content-opinions {
  border-top: 1px solid #eee;
}
.content-opinions .opinion-service {
  margin-top: 1rem;
  padding: 15px;
  border-radius: 20px;
  background: #f7f7f7;
}
.content-opinions .opinion-service .service-name {
  font-size: 1rem;
  font-family: 'tt_commonsdemibold';
  color: #16305d;
}
.content-opinions .opinion-service .service-name span {
  font-size: 0.8em;
  color: #777;
}
.content-opinions .opinion-service .service-contact {
  margin-top: 0.5rem;
}
.content-opinions .opinion-service .service-contact a {
  font-size: 1rem;
  color: #c72222;
  text-decoration: underline;
  display: block;
}
.content-opinions .opinion-service .service-text {
  margin-top: 0.5rem;
}
.content-opinions .opinion-service .service-inner-wrapper {
  display: flex;
  align-items: center;
}
.content-opinions .opinion-service .service-inner-wrapper .service-inner {
  width: 80px;
  height: 16px;
  position: relative;
  overflow: hidden;
}
.content-opinions .opinion-service .service-inner-wrapper .service-inner .inner-bar-background {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 98%;
  width: 98%;
  background: #ddd;
}
.content-opinions .opinion-service .service-inner-wrapper .service-inner .inner-bar {
  position: absolute;
  top: 1%;
  left: 1%;
  height: 98%;
  background: #c72222;
}
.content-opinions .opinion-service .service-inner-wrapper .service-inner .inner-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.content-opinions .opinion-service .service-inner-wrapper .service-number {
  margin-left: 10px;
  transform: translateY(3px);
  font-size: 0.9rem;
  color: #333;
}
.content-opinions .opinion-service .service-answer {
  padding-left: 15px;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #ccc;
}
.content-opinions .opinion-service .service-answer span {
  font-size: 0.6rem;
  color: #999;
}
.content-opinions .opinion-service .service-answer p {
  font-size: 0.8rem;
}
.content-opinions .opinion-service .service-text-editor {
  margin-top: 0.5rem;
}
.content-opinions .opinion-service .service-text-editor .input-service label {
  padding-left: 0;
}
.content-opinions .opinion-service .service-text-editor .input-service .service-inner {
  border-top-left-radius: 0;
}
.content-opinions .opinion-service .service-text-editor .btn {
  margin-top: 0.5rem;
}
.content-opinions .btn-text {
  margin-top: 1rem;
  display: block;
}
section.blog-section-content {
  padding: 2rem 0 0 0;
}
section.blog-section-content .content-search-engine {
  border-radius: 20px;
  background: #f3f3f3;
  text-align: center;
  max-width: 800px;
  padding: 15px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
section.blog-section-content .content-search-engine .form-service {
  text-align: left;
  max-width: 100%;
}
section.blog-section-content .content-search-engine .form-service .input-service {
  margin: 0;
}
section.blog-section-content .content-search-engine .form-service .btn {
  margin-top: 1rem;
  height: 40px;
}
section.blog-section-content .news-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.blog-section-content .news-flexbox .news-service {
  margin-top: 5%;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: all 0.4s;
}
section.blog-section-content .news-flexbox .news-service .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  border-radius: 15px;
  overflow: hidden;
  display: block;
  transition: all 0.4s;
  position: relative;
}
section.blog-section-content .news-flexbox .news-service .service-image-wrapper .image-cover {
  position: absolute;
  z-index: 121;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #16305d;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s;
}
section.blog-section-content .news-flexbox .news-service .service-image-wrapper .service-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
section.blog-section-content .news-flexbox .news-service .service-image-wrapper .service-image img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
}
section.blog-section-content .news-flexbox .news-service .date {
  margin-top: 7px;
  font-size: 0.7rem;
  font-family: 'tt_commonsdemibold';
  color: #888;
  display: block;
}
section.blog-section-content .news-flexbox .news-service .name {
  margin-top: 3px;
  color: #000;
  font-size: 1rem;
  line-height: 1.2rem;
  padding-right: 1rem;
  display: block;
  transition: all 0.4s;
}
section.blog-section-content .news-flexbox .news-service:hover .service-image-wrapper .image-cover {
  opacity: 0.4;
}
section.blog-section-content .news-flexbox .news-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
section.blog-section-content .tag-cloud {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
section.blog-section-content .tag-cloud p,
section.blog-section-content .tag-cloud a {
  margin-right: 10px;
  font-size: 0.7rem;
}
section.blog-section-content .tag-cloud p:not(:first-child),
section.blog-section-content .tag-cloud a:not(:first-child) {
  font-weight: 600;
}
section.blog-section-content .tag-cloud a {
  font-family: 'tt_commonsdemibold';
  color: #c72222;
}
section.blog-section-content .btn-more-products-wrapper {
  margin-top: 2rem;
}
section.blog-section-content .btn-more-products-wrapper .more-products-counter {
  margin-bottom: 1rem;
}
section.blog-section-content .btn-more-products-wrapper .more-products-counter .number {
  font-size: 12px;
  color: #000;
  margin-bottom: 10px;
}
section.blog-section-content .btn-more-products-wrapper .more-products-counter .line {
  margin: 0 auto;
  width: 170px;
  height: 2px;
  background: #eee;
  position: relative;
}
section.blog-section-content .btn-more-products-wrapper .more-products-counter .line .line-inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #333;
}
section.static-page-section-content {
  padding: 2rem 0 0 0;
  max-width: 800px;
  margin: 0 auto;
}
section.static-page-section-content .content-inner {
  margin-top: 1rem;
}
section.static-page-section-content .content-inner .inner-info {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
section.static-page-section-content .content-inner .inner-info .info-service {
  font-size: 0.8rem;
  margin-right: 1rem;
}
section.static-page-section-content .content-inner .inner-info .info-service a {
  color: #c72222;
}
section.static-page-section-content .content-inner .inner-main-image {
  margin-top: 1rem;
  width: 100%;
  padding-top: 60%;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}
section.static-page-section-content .content-inner .inner-main-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
section.static-page-section-content .content-inner .inner-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.static-page-section-content .content-inner .inner-gallery .gallery-service {
  margin-top: calc(4% / 3);
  display: block;
  width: 24%;
  padding-top: 20%;
  position: relative;
  overflow: hidden;
  background: #f9f9f9;
}
section.static-page-section-content .content-inner .inner-gallery .gallery-service img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
section.static-page-section-content .content-inner .inner-gallery .gallery-service.service-hidden {
  margin-top: 0;
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
}
section.static-page-section-content .content-inner .inner-gallery.gallery-collapsed {
  margin-top: 0;
  padding-top: 0;
}
section.static-page-section-content .content-inner .btn-gallery-more {
  margin-top: 30px;
}
section.static-page-section-content .content-inner .btn-gallery-more.hidden {
  display: none;
}
section.static-page-section-content .content-inner .inner-description {
  margin-top: 1rem;
}
section.static-page-section-content .content-inner .inner-description h1 {
  font-size: 1.5rem;
  color: #16305d;
  font-family: "tt_commons_boldbold";
}
section.static-page-section-content .content-inner .inner-description h2 {
  font-size: 1.3rem;
  color: #16305d;
  font-family: "tt_commons_boldbold";
}
section.static-page-section-content .content-inner .inner-description h3 {
  font-size: 1.15rem;
  color: #16305d;
  font-family: "tt_commons_boldbold";
}
section.static-page-section-content .content-inner .inner-description ul,
section.static-page-section-content .content-inner .inner-description ol {
  padding-left: 17px;
}
section.static-page-section-content .content-inner .inner-description p,
section.static-page-section-content .content-inner .inner-description li {
  font-size: 1rem;
  color: #333;
  line-height: 1.3rem;
}
section.static-page-section-content .content-inner .inner-steps .step-service {
  margin-top: 1.5rem;
  display: block;
}
section.static-page-section-content .content-inner .inner-steps .step-service .service-image {
  margin: 0 auto;
  background: #f9f9f9;
  border-radius: 50%;
  height: 130px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
section.static-page-section-content .content-inner .inner-steps .step-service .service-image img {
  height: 30px;
}
section.static-page-section-content .content-inner .inner-steps .step-service .service-image svg {
  height: 55px;
}
section.static-page-section-content .content-inner .inner-steps .step-service .service-image .image-number {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 2rem;
  font-family: 'tt_commonsdemibold';
  color: #000;
  opacity: 0.3;
}
section.static-page-section-content .content-inner .inner-steps .step-service .service-name {
  text-align: center;
  max-width: 200px;
  margin: 0.7rem auto 0 auto;
}
section.static-page-section-content .content-inner .inner-steps .step-service .service-name h3 {
  font-size: 1.2rem;
  font-family: 'tt_commonsdemibold';
  color: #16305d;
}
section.static-page-section-content .content-inner .inner-steps .step-service .service-name p {
  margin-top: 0.2rem;
  font-size: 0.8rem;
  color: #000;
  line-height: 1rem;
}
section.static-page-section-content .content-inner .inner-steps .step-service + .step-service {
  margin-top: 2.5rem;
}
section.static-page-section-content .content-inner .accordion {
  margin-top: 1rem;
}
section.static-page-section-content .content-inner .inner-download {
  margin-top: 2rem;
}
section.static-page-section-content .content-inner .inner-download ul li a {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 15px;
  padding: 10px;
}
section.static-page-section-content .content-inner .inner-download ul li a svg {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  fill: #c72222;
  margin-right: 10px;
}
section.static-page-section-content .content-inner .inner-download ul li a span {
  font-size: 1rem;
  transform: translateY(2px);
  color: #333;
  text-decoration: underline;
  transition: all 0.4s;
}
section.static-page-section-content .content-inner .inner-download ul li a:hover span {
  color: #16305d;
}
section.static-page-section-content .content-inner .inner-download ul li + li {
  margin-top: 0.5rem;
}
section.centrum-pomocy-section-content {
  padding-top: 2rem;
}
section.centrum-pomocy-section-content .heading {
  text-align: center;
  margin-bottom: 1rem;
}
section.centrum-pomocy-section-content .heading p {
  font-size: 0.8rem;
  color: #000;
}
section.centrum-pomocy-section-content .heading.margin-top {
  margin-top: 2rem;
}
section.centrum-pomocy-section-content .heading.text-left {
  text-align: left;
}
section.centrum-pomocy-section-content .content-search-engine {
  border-radius: 20px;
  background: #f3f3f3;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 2rem;
}
section.centrum-pomocy-section-content .content-search-engine .form-service {
  text-align: left;
  max-width: 100%;
}
section.centrum-pomocy-section-content .content-search-engine .form-service .input-service {
  margin: 0;
}
section.centrum-pomocy-section-content .content-search-engine .form-service .btn {
  margin-top: 1rem;
  height: 40px;
}
section.centrum-pomocy-section-content .content-flexbox {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service {
  width: 100%;
  padding: 2rem 0;
  position: relative;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul {
  margin-top: 25px;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li button,
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li a {
  padding: 0 1rem 0 0;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
  background: none;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  outline: none;
  transition: all 0.3s;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li button:after,
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li a:after {
  content: "";
  height: 7px;
  width: 7px;
  background: url("../img/icons/right-chevron.svg") center;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li button:hover,
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li a:hover {
  color: #c72222;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service ul li + li {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 0.5px dotted #ccc;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service:after {
  content: none;
  height: 100%;
  width: 1px;
  border-right: 1px dotted #16305d;
  opacity: 0.4;
  position: absolute;
  top: 0;
  right: 0;
}
section.centrum-pomocy-section-content .content-flexbox .flexbox-service:before {
  content: '';
  height: 1px;
  width: 100%;
  border-bottom: 1px dotted #16305d;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
}
section.centrum-pomocy-section-content .content-form-wrapper {
  background: #f9f9f9;
  margin-top: 2rem;
  padding: 2rem 0;
}
section.centrum-pomocy-section-content .content-form-wrapper .content-form .section-heading {
  text-align: left;
}
section.centrum-pomocy-section-content .content-form-wrapper .content-form .form-inner {
  width: 700px;
  max-width: 100%;
}
section.centrum-pomocy-section-content .content-form-wrapper .content-form .form-inner .btn {
  margin-top: 20px;
}
section.centrum-pomocy-section-content .content-videos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.centrum-pomocy-section-content .content-videos .video-service {
  margin-top: 2rem;
  width: 100%;
  padding-top: 66%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
section.centrum-pomocy-section-content .content-videos .video-service iframe {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
section.centrum-pomocy-section-content .content-videos .video-service.service-hidden {
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
}
.footer-ue {
  margin-top: 2rem;
  text-align: center;
}
.footer-ue img {
  width: 100%;
  max-width: 600px;
}
section.section-footer {
  margin-top: 2rem;
  padding-top: 2rem;
  position: relative;
  background: #16305d;
}
section.section-footer .footer-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
section.section-footer .footer-inner .inner-service {
  margin-top: 1rem;
  text-align: center;
  width: 50%;
}
section.section-footer .footer-inner .inner-service ul .heading {
  white-space: nowrap;
  font-size: 1rem;
  color: #fff;
  font-family: "tt_commons_boldbold";
}
section.section-footer .footer-inner .inner-service ul li a {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: all 0.4s;
}
section.section-footer .footer-inner .inner-service ul li a:hover {
  color: #c72222;
}
section.section-footer .footer-inner .inner-service ul li + li {
  margin-top: 5px;
}
section.section-footer .footer-inner .inner-service .social-media {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-footer .footer-inner .inner-service .social-media li a svg {
  height: 30px;
  width: 30px;
  fill: rgba(255, 255, 255, 0.7);
  transition: all 0.5s;
}
section.section-footer .footer-inner .inner-service .social-media li a svg:hover {
  fill: #c72222;
}
section.section-footer .footer-inner .inner-service .social-media li + li {
  margin-top: 0;
}
section.section-footer .footer-inner .inner-service .social-media li:not(:last-child) {
  margin-right: 4px;
}
section.section-footer .footer-inner .inner-service address {
  margin-top: 5px;
  margin-bottom: 0;
}
section.section-footer .footer-inner .inner-service address a {
  display: block;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.5s;
}
section.section-footer .footer-inner .inner-service address a:hover {
  color: #c72222;
}
section.section-footer .footer-inner .inner-service address p {
  margin-top: 15px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}
section.section-footer .footer-inner .inner-service .btn {
  margin-top: 20px;
}
section.section-footer .footer-inner .inner-service:last-child {
  width: 100%;
}
section.section-footer .footer-credits {
  margin-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
}
section.section-footer .footer-credits .credits-cta .cta-button {
  margin: 0 auto 1rem auto;
  display: flex;
  align-items: center;
  background: #c72222;
  padding: 0.2rem;
  border-radius: 100px;
  max-width: 200px;
}
section.section-footer .footer-credits .credits-cta .cta-button .button-icon {
  min-width: 2.3rem;
  max-width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-footer .footer-credits .credits-cta .cta-button .button-icon svg {
  height: 1.4rem;
  width: 1.4rem;
  fill: #16305d;
}
section.section-footer .footer-credits .credits-cta .cta-button span {
  font-size: 1rem;
  color: #fff;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
}
section.section-footer .footer-credits .credits-copyright {
  text-align: center;
}
section.section-footer .footer-credits .credits-copyright .copyright-logo .logo-image img {
  height: 30px;
}
section.section-footer .footer-credits .credits-copyright p {
  margin-top: 0.5rem;
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.7);
}
section.section-footer .footer-credits .credits-copyright p a {
  color: inherit;
  transition: all 0.5s;
}
section.section-footer .footer-credits .credits-copyright p a:hover {
  color: #16305d;
}
section.panel-klienta-logowanie-section-content {
  padding: 2rem 0 0 0;
}
section.panel-klienta-logowanie-section-content .content-login {
  display: flex;
  justify-content: center;
}
section.panel-klienta-logowanie-section-content .content-login .login-service {
  width: 100%;
  max-width: 500px;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .heading {
  text-align: center;
  margin-bottom: 1rem;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .heading p {
  font-size: 0.8rem;
  color: #000;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .info {
  margin-top: 1rem;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .info p {
  font-size: 0.7rem;
  padding-left: 17px;
  color: #000;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .checkbox-wrapper {
  margin-top: 1rem;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .social-media-login {
  margin-top: 2rem;
  border: none;
  padding: 10px;
  background: #f3f3f3;
  width: 100%;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .social-media-login svg {
  height: 25px;
  min-width: 25px;
  max-width: 25px;
  margin-right: 20px;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .social-media-login p {
  color: #000;
  transition: all 0.4s;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .social-media-login + .social-media-login {
  margin-top: 0.5rem;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .social-media-login:hover {
  background: #444;
  border-color: #444;
}
section.panel-klienta-logowanie-section-content .content-login .login-service .social-media-login:hover p {
  color: #fff;
}
section.panel-klienta-section-content {
  padding: 2rem 0 0 0;
}
section.panel-klienta-section-content .heading {
  text-align: center;
  margin-bottom: 1rem;
}
section.panel-klienta-section-content .heading p {
  font-size: 0.8rem;
  color: #000;
}
section.panel-klienta-section-content .heading svg {
  fill: #16305d;
  height: 1.2rem;
  width: 1.2rem;
  transform: translateY(-5px);
}
section.panel-klienta-section-content .content-quick-click .quick-click-camera {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background: #f9f9f9;
  border-radius: 20px;
}
section.panel-klienta-section-content .content-quick-click .quick-click-camera video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
section.panel-restauratora-section-content {
  padding: 2rem 0 0 0;
}
section.panel-restauratora-section-content .heading {
  text-align: center;
  margin-bottom: 1rem;
}
section.panel-restauratora-section-content .heading p {
  font-size: 0.8rem;
  color: #000;
}
section.panel-restauratora-section-content .heading.margin-top {
  margin-top: 2rem;
}
section.panel-restauratora-section-content .heading.text-left {
  text-align: left;
}
section.panel-restauratora-section-content .content-dashboard .dashboard-cards .card-service {
  margin-top: 1rem;
  display: block;
  text-align: center;
}
section.panel-restauratora-section-content .content-dashboard .dashboard-cards .card-service .service-image {
  padding: 50px 30px;
  border-radius: 20px;
}
section.panel-restauratora-section-content .content-dashboard .dashboard-cards .card-service .name {
  display: block;
  margin-top: 1rem;
  font-style: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: #000;
}
section.panel-restauratora-section-content .content-dashboard .dashboard-cards .card-service .address {
  font-size: 0.7rem;
  color: #000;
}
section.panel-restauratora-section-content .content-manager .btn {
  margin-top: 0.5rem;
}
section.panel-restauratora-section-content .content-manager .manager-list {
  margin-top: 2rem;
}
section.panel-restauratora-section-content .content-manager .manager-list .list-service {
  position: relative;
}
section.panel-restauratora-section-content .content-manager .manager-list .list-service .name {
  font-size: 0.9rem;
}
section.panel-restauratora-section-content .content-manager .manager-list .list-service .mail {
  font-size: 0.7rem;
  line-height: 0.7rem;
}
section.panel-restauratora-section-content .content-manager .manager-list .list-service + .list-service {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #ccc;
}
section.panel-restauratora-section-content .content-objects .objects-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.panel-restauratora-section-content .content-objects .objects-wrapper .object-service {
  margin-top: 1rem;
  width: 48%;
  border-radius: 15px;
  background: #f3f3f3;
  padding: 15px;
  text-align: center;
}
section.panel-restauratora-section-content .content-objects .objects-wrapper .object-service .name {
  font-size: 0.9rem;
  color: #777;
}
section.panel-restauratora-section-content .content-objects .objects-wrapper .object-service .number {
  font-size: 1.2rem;
  color: #000;
  font-family: "tt_commonsdemibold";
}
section.panel-restauratora-section-content .content-plan {
  margin-top: 1rem;
  padding: 15px;
  border-radius: 20px;
  background: #f7f7f7;
  text-align: center;
}
section.panel-restauratora-section-content .content-plan .btn {
  margin-top: 0.5rem;
}
section.panel-restauratora-section-content .content-plan-disactive {
  margin-top: 1rem;
  text-align: center;
}
section.panel-restauratora-section-content .content-balance {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #ccc;
  text-align: center;
}
section.panel-restauratora-section-content .content-balance .balance-value {
  font-size: 2rem;
}
section.panel-restauratora-section-content .content-packages {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #ccc;
  text-align: center;
}
section.panel-restauratora-section-content .content-packages .packages-info {
  margin-top: 1rem;
  font-size: 1.2rem;
}
section.panel-restauratora-section-content .content-packages .packages-info strong {
  font-family: "tt_commonsdemibold";
  color: #c72222;
}
section.panel-restauratora-section-content .content-packages .packages-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service {
  margin-top: 1rem;
  width: 32%;
  border-radius: 15px;
  background: #f3f3f3;
  padding: 15px;
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service .name {
  font-size: 1.2rem;
  color: #16305d;
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service .name b {
  font-family: "tt_commons_boldbold";
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service .value {
  font-size: 0.8rem;
  color: #16305d;
  font-family: "tt_commons_boldbold";
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service .price {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: #c72222;
  font-family: "tt_commonsdemibold";
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service .price small {
  font-size: 0.7em;
  text-decoration: line-through;
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service .promotion {
  font-size: 0.8rem;
  color: #000;
}
section.panel-restauratora-section-content .content-packages .packages-wrapper .package-service .btn {
  margin-top: 1rem;
  padding: 0;
}
section.panel-restauratora-section-content .content-plans {
  text-align: center;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service {
  width: 100%;
  position: relative;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service .service-box {
  display: block;
  margin-top: 1rem;
  border-radius: 15px;
  border: 1px solid #f3f3f3;
  background: #f3f3f3;
  padding: 15px;
  transition: all 0.5s;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service .service-box .name {
  font-size: 1.2rem;
  color: #16305d;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service .service-box .name b {
  font-family: "tt_commons_boldbold";
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service .service-box .info {
  font-size: 0.85rem;
  color: #000;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service .service-box .info a {
  text-decoration: underline;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service .service-box .price {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: #c72222;
  font-family: "tt_commonsdemibold";
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service .service-box .btn {
  margin-top: 1rem;
  padding: 0;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service input {
  visibility: visible;
  opacity: 0;
  left: 0;
  position: absolute;
  width: auto !important;
}
section.panel-restauratora-section-content .content-plans .plans-wrapper .plan-service > input:checked + .service-box {
  background: #fff;
  border: 1px solid #c72222;
}
section.panel-restauratora-section-content .content-plans .form-service {
  text-align: left;
}
section.panel-restauratora-section-content .content-plans .heading-h2 {
  margin-top: 2rem;
}
section.panel-restauratora-section-content .content-quick-click .quick-click-objects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.panel-restauratora-section-content .content-quick-click .quick-click-objects .object-service {
  margin-top: 1rem;
  width: 48%;
  border-radius: 15px;
  background: #f3f3f3;
  padding: 15px;
  text-align: center;
  position: relative;
}
section.panel-restauratora-section-content .content-quick-click .quick-click-objects .object-service .time {
  position: absolute;
  top: 5px;
  right: 5px;
  padding-top: 2px;
  padding-right: 2px;
  border-radius: 4px 12px 4px 4px;
  background: #c72222;
  color: #fff;
  font-size: 0.8rem;
  letter-spacing: -0.03rem;
  font-family: "tt_commonsdemibold";
  height: 20px;
  width: 40px;
}
section.panel-restauratora-section-content .content-quick-click .quick-click-objects .object-service .name {
  font-size: 0.9rem;
  color: #777;
}
section.panel-restauratora-section-content .content-quick-click .quick-click-objects .object-service .number {
  font-size: 1.2rem;
  color: #000;
  font-family: "tt_commonsdemibold";
}
section.panel-restauratora-section-content .content-quick-click .quick-click-objects .object-service.service-alert {
  display: block;
  animation: blink 1s linear infinite;
  position: relative;
}
section.panel-restauratora-section-content .content-quick-click .quick-click-objects .object-service.service-alert:after {
  content: attr(data-elmts-alert);
  position: absolute;
  font-family: "tt_commonsdemibold";
  left: 50%;
  top: 3px;
  transform: translateX(-50%);
  font-size: 0.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: red;
}
@keyframes blink {
  0% {
    box-shadow: 0 0 15px green;
  }
  50% {
    box-shadow: 0 0 0;
  }
  100% {
    box-shadow: 0 0 15px green;
  }
}
section.panel-restauratora-section-content .content-search-engine {
  border-radius: 15px;
  background: #f3f3f3;
  padding: 15px 15px 0 15px;
  text-align: center;
  margin-bottom: 2rem;
}
section.panel-restauratora-section-content .content-search-engine .form-service {
  text-align: left;
}
section.panel-restauratora-section-content .preloader-wrapper {
  width: 50px;
  height: 50px;
  margin: 35px auto 50px auto;
}
section.panel-restauratora-section-content #preloader {
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 0px;
  animation: preloader 4.5s infinite linear;
}
section.panel-restauratora-section-content #preloader:after {
  content: '';
  width: 14px;
  height: 14px;
  background: black;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  border-radius: 20px;
  animation: preloader_after 4.5s infinite linear;
  transform: scale(0);
}
@keyframes preloader {
  0% {
    -webkit-transform: scale(0);
  }
  10% {
    -webkit-transform: scale(1.3);
  }
  12% {
    -webkit-transform: scale(1);
  }
  15% {
    -webkit-transform: scale(1.3);
  }
  17% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1) rotate(180deg);
    border-radius: 20px;
  }
  42% {
    -webkit-transform: scale(1) rotate(180deg);
    border-radius: 0px;
  }
  44% {
    -webkit-transform: scale(1) rotate(180deg);
    border-radius: 20px;
  }
  46% {
    -webkit-transform: scale(1) rotate(180deg);
    border-radius: 0px;
  }
  48% {
    -webkit-transform: scale(1) rotate(180deg);
    border-radius: 20px;
  }
  50% {
    -webkit-transform: scale(1) rotate(180deg);
    border-radius: 20px;
  }
  95% {
    -webkit-transform: scale(1) rotate(180deg);
    border-radius: 20px;
  }
  100% {
    -webkit-transform: scale(0) rotate(180deg);
    border-radius: 100px;
  }
}
@keyframes preloader_after {
  0% {
    transform: scale(0);
  }
  45% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  55% {
    transform: scale(1) translateY(-20px) translateX(-14px);
  }
  60% {
    transform: scale(1) translateY(20px) translateX(14px);
  }
  65% {
    transform: scale(1) translateY(-20px) translateX(14px);
  }
  70% {
    transform: scale(1) translateY(20px) translateX(-14px);
  }
  75% {
    transform: scale(1) translateY(-20px) translateX(14px);
  }
  80% {
    transform: scale(1) translateY(20px) translateX(-14px);
  }
  85% {
    transform: scale(1) translateY(-20px) translateX(-14px);
  }
  90% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  95% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}
@media (min-width: 430px) {
  .offer-items-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .offer-items-wrapper .offer-item {
    margin-top: 1rem;
    width: 48% !important;
  }
  .offer-items-wrapper .wrapper-buttons {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .cookies-alert {
    bottom: 40px;
    left: 40px;
    width: 330px;
  }
}
@media (min-width: 768px) {
  .offer-items-wrapper .offer-item {
    width: 32% !important;
  }
  section.blog-section-content .content-search-engine .form-service .btn {
    margin-top: 0;
  }
  section.blog-section-content .news-flexbox .news-service {
    margin-top: 5%;
    width: 31%;
  }
  section.centrum-pomocy-section-content .content-search-engine .form-service .btn {
    margin-top: 0;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service {
    width: 31%;
    padding: 2rem;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:after {
    content: '';
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(3n):after {
    content: none;
  }
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(1):before,
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(2):before,
  section.centrum-pomocy-section-content .content-flexbox .flexbox-service:nth-child(3):before {
    content: none;
  }
  section.centrum-pomocy-section-content .content-form-wrapper .content-form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section.centrum-pomocy-section-content .content-form-wrapper .content-form .form-inner {
    margin-left: 100px;
  }
  section.centrum-pomocy-section-content .content-videos .video-service {
    width: 31%;
    padding-top: 20.46%;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 17px;
  }
  .toast-container .container-inner {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .toast-container .toast {
    max-width: 400px;
  }
  .toast-container .toast + .toast {
    margin-top: 0.5rem;
  }
  .breadcrumb-wrapper .breadcrumb li {
    display: block;
    width: auto;
    font-size: 0.7rem;
  }
  .breadcrumb-wrapper .breadcrumb li:before {
    display: block;
  }
  .breadcrumb-wrapper .breadcrumb li a {
    display: inline-block;
    padding: 0;
    border-radius: 0;
    background: none;
    width: auto;
  }
  .breadcrumb-wrapper .breadcrumb li a:before {
    content: none;
  }
  .breadcrumb-wrapper .breadcrumb li a span {
    transform: translateY(0);
    font-size: 0.7rem;
  }
  .breadcrumb-wrapper .breadcrumb li + li {
    padding-left: 0.5rem;
  }
  .breadcrumb-wrapper .breadcrumb li:nth-last-child(2) {
    display: block;
  }
  .breadcrumb-wrapper + section.section-welcome {
    margin-top: 1rem;
  }
  .offer-items-wrapper .offer-item {
    width: 24% !important;
  }
  nav.section-header {
    background: #f9f9f9;
  }
  nav.section-header .header-flexbox {
    padding: 25px 0;
  }
  nav.section-header .header-flexbox .flexbox-logo img {
    height: 40px;
  }
  nav.section-header .header-flexbox .flexbox-inner ul li button .button-name {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-inner ul li button .button-icon {
    background: #fff;
  }
  nav.section-header.background-white {
    background: #fff;
  }
  nav.section-header.background-white .header-flexbox .flexbox-inner ul li button .button-icon {
    background: #f7f7f7;
  }
  nav.section-header.background-blue {
    background: #16305d;
  }
  section.section-welcome {
    position: relative;
  }
  section.section-welcome .container {
    max-width: 100%;
  }
  section.section-welcome .swiper-welcome {
    border-radius: 20px 20px 0 0;
  }
  section.section-welcome .welcome-item {
    border-radius: 20px 20px 0 0;
  }
  section.section-welcome .welcome-item .item-description {
    height: 300px;
  }
  section.section-welcome .welcome-search-engine {
    margin: 0;
    width: 100%;
    border-radius: 0 0 20px 20px;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper {
    display: flex;
    align-items: center;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 {
    justify-content: space-between;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 > .input-service {
    width: 100%;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 > .input-service + .inner-row {
    margin-left: 1rem;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 .inner-row {
    width: 100%;
    flex-wrap: nowrap;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 .inner-row > * {
    width: 100%;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 .inner-row > * + * {
    margin-left: 1rem;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 .inner-row + .inner-row {
    margin-left: 1rem;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 .inner-row.w-200 {
    width: 200%;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-100 .inner-row + .input-service {
    margin-left: 1rem;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-auto .inner-row {
    width: auto;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-auto .inner-row > * {
    width: auto;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-auto .inner-row > * + * {
    margin-left: 1rem;
  }
  section.section-welcome .welcome-search-engine .search-engine-inner .inner-row-wrapper.width-auto .inner-row + .inner-row {
    margin-left: 1rem;
  }
  section.section-welcome .welcome-search-engine .heading-h1 {
    text-align: center;
  }
  section.section-welcome.welcome-offer {
    max-width: 800px;
    margin: 1rem auto 0 auto;
  }
  section.section-welcome.welcome-offer .welcome-item {
    border-radius: 20px;
  }
  section.section-offer .last-minute-heading {
    display: none;
  }
  section.section-zero-waste-info {
    display: block;
  }
  section.section-zero-waste {
    margin-top: 0;
    padding-top: 2rem;
  }
  section.section-zero-waste .zero-waste-heading {
    display: none;
  }
  section.last-minute-section-content .content-inner .inner-filter-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  section.last-minute-section-content .content-inner .heading-filter-button {
    width: auto;
    margin-right: 2rem;
  }
  section.last-minute-section-content .content-inner .heading-filter-info {
    margin-top: 1rem;
  }
  section.section-newsletter {
    padding-top: 2rem;
    text-align: center;
  }
  section.section-newsletter .newsletter-inner {
    max-width: 500px;
    margin: 1rem auto 0 auto;
  }
  section.section-footer .footer-inner .inner-service {
    width: auto;
  }
  section.section-footer .footer-inner .inner-service:last-child {
    width: auto;
  }
  section.section-footer .footer-credits {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  section.section-footer .footer-credits .credits-cta {
    display: flex;
    align-items: center;
  }
  section.section-footer .footer-credits .credits-cta .cta-button {
    margin: 0;
  }
  section.section-footer .footer-credits .credits-cta .cta-button + .cta-button {
    margin-left: 1rem;
  }
  section.section-footer .footer-credits .credits-copyright {
    text-align: left;
    display: flex;
    align-items: center;
  }
  section.section-footer .footer-credits .credits-copyright p {
    margin-top: 0;
    margin-left: 1.5rem;
  }
  section.static-page-section-content .content-inner .inner-steps {
    display: flex;
    justify-content: space-between;
  }
  section.static-page-section-content .content-inner .inner-steps .step-service {
    padding: 0 10px;
  }
  section.static-page-section-content .content-inner .inner-steps .step-service + .step-service {
    margin-top: 1.5rem;
  }
  section.panel-klienta-section-content {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
  }
  section.panel-klienta-section-content .content-inner-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  section.panel-klienta-section-content .content-inner-wrapper .inner-wrapper-sidebar {
    width: 30%;
  }
  section.panel-klienta-section-content .content-inner-wrapper .inner-wrapper-body {
    width: 65%;
  }
  section.panel-restauratora-section-content {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
  }
  section.panel-restauratora-section-content .content-inner-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  section.panel-restauratora-section-content .content-inner-wrapper .inner-wrapper-sidebar {
    width: 30%;
  }
  section.panel-restauratora-section-content .content-inner-wrapper .inner-wrapper-body {
    width: 65%;
  }
  section.panel-restauratora-section-content .content-inner-wrapper .inner-wrapper-body .heading-h1 {
    margin-top: 1rem;
  }
  section.panel-restauratora-section-content .content-inner-wrapper .inner-wrapper-body .swiper {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) {
  .offer-items-wrapper .offer-item {
    width: 19% !important;
  }
  section.blog-section-content .news-flexbox .news-service {
    margin-top: 5%;
    width: 24%;
  }
}
@media (min-width: 1400px) {
  section.section-welcome .container {
    max-width: 1320px;
    position: relative;
  }
  section.section-zero-waste-info {
    padding: 4rem 0;
  }
}
